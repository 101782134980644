import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const document = {
  state: {
    documents: [],
    selectedDocument: {},
    selectedDocumentOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    documents(state) {
      return state.documents
    },
    selectedDocument(state) {
      return state.selectedDocument
    },
    selectedDocumentOriginal(state) {
      return state.selectedDocumentOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    documents(state, payload) {
      state.documents = payload
    },
    selectedDocument(state, payload) {
      state.selectedDocument = JSON.parse(JSON.stringify(payload))
    },
    selectedDocumentOriginal(state, payload) {
      state.selectedDocumentOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedDocument(context, payload) {
      context.commit('selectedDocument', payload)
    },
    async loadDocuments(context, payload) {
      const url = 'Document'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('documents', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedDocument(context, payload) {
      if (context.state.selectedDocument && context.state.selectedDocument.Id > 0) {
        context.dispatch('loadSelectedDocument', context.state.selectedDocument.Id)

      }
    },
    async loadSelectedDocument(context, payload) {
      if (context.state.selectedDocument && context.state.selectedDocument.Id != payload) {
        context.commit('selectedDocument', {})
      }
      const url = 'Document/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedDocument', res.data)
        context.commit('selectedDocumentOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadDocuments(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadDocuments')
      }, )
    },
    async createDocument(context, payload){
      const url = `Document`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'document created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadDocuments')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchDocument(context, payload){
      var item = context.state.selectedDocument
      if (payload.Id && 
      payload.Id == context.state.selectedDocumentOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedDocumentOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `Document/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'document updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadDocuments')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateDocument(context, payload){
      const url = `Document/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'document updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadDocuments')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveDocument(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateDocument', payload)
      } else {
        return context.dispatch('createDocument', payload)
      }
    },
    async deleteDocument(context, payload){
      const url = `Document/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'document deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadDocuments')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default document