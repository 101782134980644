import Vue from 'vue'
import getConfig from '@config/appinfo.js'
import jwtDecode from 'jwt-decode'

export const state = {
  currentUser: null,
  gapiEmail: null,
  gapiToken: null,
  signingIn: false,
  etsJwtToken: null,
  identityUrl: null,
  isRefreshing: false,
  refreshingCall: null,
  //fixme: if you are only going to have google logins, set the singleProvider and loginType to 'google'
  singleProvider: false,
  //setting the login type to 'google' will allow the app to attempt to get the google identity upon page load/refresh
  // if it does not get a google identity it will fall back to retrieve username and password credentials from the browser api
  loginType: 'google',
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
  },
  SET_GAPI_USER(state, payload) {
    if (!!payload) {
      state.gapiEmail = payload.email
      state.gapiToken = payload.token
    } else {
      state.gapiEmail = null
      state.gapiToken = null
    }
  },
  SET_ETS_JWT_TOKEN(state, newValue) {
    state.etsJwtToken = newValue
  },
  SET_IDENTITY_URL(state, newValue) {
    state.identityUrl = newValue
  },
  SET_SIGNING_IN(state, value) {
    state.signingIn = value
  },
  setRefreshingState(state, isRefreshing) {
    state.isRefreshing = isRefreshing
  },
  setRefreshingCall(state, func) {
    state.refreshingCall = func
  },
  setLoginType(state, payload) {
    state.loginType = payload
  },
}

export const getters = {
  // Whether the user is currently logged in.
  isLoggedIn(state, getters) {
    //once you have a user object being returned and saved you also want to check that that has been populated
    return (
      !!state.etsJwtToken &&
      !!getters.hasCurrentToken &&
      !!state.currentUser &&
      state.currentUser.Id > 0
    )
  },
  isAdmin(state, getters) {
    return getters.userRoles.includes('Admin')
  },
  isConsumer(state, getters) {
    return getters.userRoles.includes('Consumer')
  },
  isDeveloper(state, getters) {
    return getters.userRoles.includes('Developer')
  },
  userRoles(state) {
    if (state.currentUser != null && state.currentUser.Roles.length > 0) {
      return state.currentUser.Roles.map((role) => role.Description)
    } else {
      return []
    }
  },
  decodedToken(state) {
    if (!!state.etsJwtToken) {
      return jwtDecode(state.etsJwtToken)
    } else {
      return null
    }
  },
  decodedGoogleToken(state) {
    if (!!state.gapiToken) {
      return jwtDecode(state.gapiToken)
    } else {
      return null
    }
  },
  tokenExpiration(state) {
    if (!!state.etsJwtToken) {
      return new Date(jwtDecode(state.etsJwtToken).exp * 1000)
    } else {
      return null
    }
  },
  hasCurrentToken(state) {
    if (!!state.etsJwtToken) {
      var token = jwtDecode(state.etsJwtToken)
      if (token.exp > Date.now() / 1000) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  tokenExpiresSoon(state) {
    if (!!state.etsJwtToken) {
      var token = jwtDecode(state.etsJwtToken)
      if (
        token.exp > Date.now() / 1000 && // not yet expired
        token.exp < Date.now() / 1000 + 30 * 60 // expires within 30 minutes
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
}

export const actions = {
  // async init({ dispatch }) {
  //   await dispatch('getIdentityUrl')
  // },
  // // Several of the un/pw actions go directly to the identity endpoint.
  // // This will get it from the backend instead of the config so that we know it matches
  async getIdentityUrl({ commit, dispatch }) {
    try {
      let config = await getConfig()
      commit('SET_IDENTITY_URL', config.EnvironmentSettings.IdentityURL)
    } catch (e) {
      dispatch('errors/handleError', e, {
        root: true,
      })
      console.error(e)
    }
  },

  // Logs out the current user.
  async logOut({ commit }) {
    console.info('logout')
    let url = `/Logout`
    Vue.prototype.$axios.get(url)
    Vue.axios.defaults.headers.common['Authorization'] = null
    if (Vue.prototype.$google) {
      var client = await Vue.prototype.$google.getClient()
      //this will prevent the auto-sign in from happening
      client.accounts.id.disableAutoSelect()
    }
    commit('SET_CURRENT_USER', null)
    commit('SET_ETS_JWT_TOKEN', null)
    commit('SET_GAPI_USER', null)
    commit('setLoginType', null)
  },

  async handleGoogleCredentials({ commit, dispatch }, payload) {
    //this is called by the callback of the google login
    commit('SET_SIGNING_IN', true)
    try {
      var userInfo = jwtDecode(payload)
      userInfo.token = payload
      userInfo.expiration = new Date(userInfo.exp * 1000)
      commit('SET_GAPI_USER', userInfo)
      commit('setLoginType', 'google')
      await dispatch('validateToken', {
        email: userInfo.email,
        token: payload,
        password: null,
      })
    } catch (e) {
      commit('SET_SIGNING_IN', false)
      commit('SET_GAPI_USER', null)
      commit('setLoginType', null)
      dispatch('errors/handleError', e, {
        root: true,
      })
    }
  },

  async handleUNPWCredentials({ commit, dispatch }, payload) {
    //this is called by the callback of the google login
    if (payload) {
      commit('SET_SIGNING_IN', true)
      try {
        commit('setLoginType', 'unpw')
        await dispatch('validateToken', {
          email: payload.id,
          password: payload.password,
          token: null,
        })
      } catch (e) {
        commit('SET_SIGNING_IN', false)
        commit('SET_GAPI_USER', null)
        commit('setLoginType', null)
        dispatch('errors/handleError', e, {
          root: true,
        })
      }
    }
  },
  async getBrowserUser({ commit, dispatch }, payload) {
    try {
      //note: the new google signin is also able to retrieve passwords stored in the browser credential manager
      // this allows us to attempt to refresh the ets jwt tokens without the user needing to actively sign in again
      if (!state.signingIn) {
        return new Promise((resolve, reject) => {
          if (
            Vue.prototype.$google &&
            (state.loginType == 'google' || payload)
          ) {
            commit('SET_SIGNING_IN', true) //does the pop up for user select
            let returned = false
            Vue.prototype.$google.prompt((notification) => {
              if (notification.isNotDisplayed()) {
                if (
                  notification.getNotDisplayedReason() ==
                  'opt_out_or_no_session'
                ) {
                  //possible un/pw retrieval
                  let x = 0
                  var intervalID = window.setInterval(() => {
                    if (state.loginType == 'unpw') {
                      returned = true
                      resolve('unpw')
                    }
                    if (++x >= 10 && !returned) {
                      returned = true
                      window.clearInterval(intervalID)
                      commit('SET_SIGNING_IN', false)
                      reject('timeout')
                    }
                  }, 30)
                  //if un/pw not used we can just reject
                  // reject('no session')
                }
              }
              if (notification.isDisplayMoment()) {
                let x = 0
                var intervalID = window.setInterval(() => {
                  // if a promise has not been resolved we are going to return a reject
                  if (returned) {
                    window.clearInterval(intervalID)
                  }
                  if (++x >= 20 && !returned) {
                    returned = true
                    window.clearInterval(intervalID)
                    commit('SET_SIGNING_IN', false)
                    reject('timeout')
                  }
                }, 500)
              }
              if (notification.isDismissedMoment()) {
                if (
                  notification.getDismissedReason() == 'credential_returned'
                ) {
                  returned = true
                  commit('SET_SIGNING_IN', false)
                  resolve('google')
                }
              }
            })
          }
        })
      }
    } catch (e) {
      dispatch('errors/handleError', e, {
        root: true,
      })
      console.error(e)
      commit('SET_SIGNING_IN', false)
      dispatch('logOut')
    }
  },
  async logInUnPw({ commit, dispatch }, payload) {
    payload.token = null
    commit('setLoginType', 'unpw')
    dispatch('validateToken', payload)
  },
  async refreshToken({ commit, getters, dispatch }, payload) {
    //only refresh token if we have a current (not expired) one
    if (getters.hasCurrentToken) {
      //note: work with google accounts as well as un/pw accounts where the user has saved their credentials in the browser
      if (getters.tokenExpiresSoon) {
        if (!!state.gapiEmail && !!state.gapiToken) {
          //this will refresh the google token if it has expired
          var token = jwtDecode(state.gapiToken)
          if (token.exp > Date.now() / 1000) {
            //if not expired, we use the token we have.
            await dispatch('handleGoogleCredentials', state.gapiToken)
          } else {
            await dispatch('getBrowserUser', true)
          }
        } else {
          await dispatch('getBrowserUser', true)
        }
      }
    } else {
      //try to refresh token from backend to see if we have a session already
      await dispatch('getSessionToken')
    }
  },
  async getSessionToken({ commit, dispatch, getters }, payload) {
    if (!state.signingIn) {
      commit('SET_SIGNING_IN', true)
      let url = `/Login`
      try {
        let response = await Vue.prototype.$axios.get(url)
        if (!!response.data) {
          var token = jwtDecode(response.data)
          // console.devlog(token)
          if (token && token.exp > Date.now() / 1000) {
            //we got back a valid auth token from the backend... let's set it
            commit('SET_ETS_JWT_TOKEN', response.data)
            Vue.axios.defaults.headers.common['Authorization'] =
              'Bearer ' + response.data
            //call this to retrieve user details / roles from the api
            if (getters.decodedToken.sub) {
              await dispatch('retrieveUserDetail', getters.decodedToken.sub)
            }
          } else {
            // token is invalid or expired try to get the user from google
            await dispatch('getBrowserUser', true)
          }
          commit('SET_SIGNING_IN', false)
        } else {
          // no current session - try to get the user from google
          await dispatch('getBrowserUser', true)
        }
      } catch (e) {
        console.error(e)
        commit('SET_SIGNING_IN', false)
      }
    }
  },
  async validateToken({ commit, dispatch }, payload) {
    commit('SET_SIGNING_IN', true)
    let url = `/Login`
    let email = payload.email
    let token = payload.token
    let password = payload.password
    if ((!!email && !!token) || (!!email && !!password)) {
      try {
        let response = await Vue.prototype.$axios.post(url, {
          email: email,
          id_token: token,
          password: password,
        })
        if (!!response.data && !!response.data.auth_token) {
          await dispatch('processLogin', response.data)
          if (state.loginType == 'unpw' && password) {
            try {
              if (Vue.prototype.$google && PasswordCredential) {
                var client = await Vue.prototype.$google.getClient()
                let creds = new PasswordCredential({
                  id: email,
                  password,
                })
                client.accounts.id.storeCredential(creds)
              }
            } catch (er) {
              console.error(er)
            }
          }
          commit('SET_SIGNING_IN', false)
        } else {
          console.error('jwt token not retrieved: ', response.data)
          dispatch('errors/handleError', response.data, {
            root: true,
          })
          dispatch('logOut')
        }
      } catch (e) {
        dispatch('errors/handleError', e, {
          root: true,
        })
        console.error(e)
        commit('SET_SIGNING_IN', false)
        dispatch('logOut')
      }
    } else {
      commit('SET_SIGNING_IN', false)
      dispatch('errors/handleError', 'check authentication', {
        root: true,
      })
    }
  },
  async processLogin({ commit, dispatch }, payload) {
    commit('SET_ETS_JWT_TOKEN', payload.auth_token)
    Vue.axios.defaults.headers.common['Authorization'] =
      'Bearer ' + payload.auth_token
    if (payload.user) {
      commit('SET_CURRENT_USER', payload.user)
    } else {
      await dispatch('retrieveUserDetail', email)
    }
  },

  async retrieveUserDetail(context, email) {
    try {
      let { data } = await Vue.prototype.$axios.get(
        `/User/ByEmail?email=${encodeURIComponent(email)}`
      )
      // call mutation to set user details in store
      context.commit('SET_CURRENT_USER', data)
    } catch (e) {
      context.dispatch('errors/handleError', e, {
        root: true,
      })
      console.error(e)
      context.dispatch('logOut')
    }
  },
}
