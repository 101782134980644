import Vue from 'vue'

const application = {
  state: {
    // selectedApplicationCreatedById: 0,
    // createdByRequestCount: 0,
    // selectedApplicationCreatedBy: {},
    selectedApplicationPeriodId: 0,
    periodRequestCount: 0,
    selectedApplicationPeriod: {},
    selectedApplicationTaxablePropertyId: 0,
    taxablePropertyRequestCount: 0,
    selectedApplicationTaxableProperty: {},
    // selectedApplicationUpdatedById: 0,
    // updatedByRequestCount: 0,
    // selectedApplicationUpdatedBy: {},
    selectedApplicationApplicationDocumentsId: 0,
    applicationDocumentsRequestCount: 0,
    selectedApplicationApplicationDocuments: [],
    selectedApplicationApplicationNotesId: 0,
    applicationNotesRequestCount: 0,
    selectedApplicationApplicationNotes: [],
    selectedApplicationApplicationStatusHistoriesId: 0,
    applicationStatusHistoriesRequestCount: 0,
    selectedApplicationApplicationStatusHistories: [],
    selectedApplicationHouseholdMembersId: 0,
    householdMembersRequestCount: 0,
    selectedApplicationHouseholdMembers: [],
  },

  getters: {
    // selectedApplicationCreatedBy(state) {
    //   return state.selectedApplicationCreatedBy
    // },
    // createdByLoading(state) {
    //     return state.createdByRequestCount > 0
    // },
    selectedApplicationPeriod(state) {
      return state.selectedApplicationPeriod
    },
    periodLoading(state) {
      return state.periodRequestCount > 0
    },
    selectedApplicationTaxableProperty(state) {
      return state.selectedApplicationTaxableProperty
    },
    taxablePropertyLoading(state) {
      return state.taxablePropertyRequestCount > 0
    },
    // selectedApplicationUpdatedBy(state) {
    //   return state.selectedApplicationUpdatedBy
    // },
    // updatedByLoading(state) {
    //     return state.updatedByRequestCount > 0
    // },
    selectedApplicationApplicationDocuments(state) {
      return state.selectedApplicationApplicationDocuments
    },
    applicationDocumentsLoading(state) {
      return state.applicationDocumentsRequestCount > 0
    },
    selectedApplicationApplicationNotes(state) {
      return state.selectedApplicationApplicationNotes
    },
    applicationNotesLoading(state) {
      return state.applicationNotesRequestCount > 0
    },
    selectedApplicationApplicationStatusHistories(state) {
      return state.selectedApplicationApplicationStatusHistories
    },
    applicationStatusHistoriesLoading(state) {
      return state.applicationStatusHistoriesRequestCount > 0
    },
    selectedApplicationHouseholdMembers(state) {
      return state.selectedApplicationHouseholdMembers
    },
    householdMembersLoading(state) {
      return state.householdMembersRequestCount > 0
    },
  },

  mutations: {
    // selectedApplicationCreatedBy(state, payload) {
    //   state.selectedApplicationCreatedBy = payload
    // },
    // selectedApplicationCreatedById(state, payload) {
    //   state.selectedApplicationCreatedById = payload
    // },
    // createdByRequestCount(state, payload) {
    //   state.createdByRequestCount = payload
    // },
    // increaseCreatedByRequestCount(state, payload) {
    //   state.createdByRequestCount = state.createdByRequestCount + 1
    // },
    // decreaseCreatedByRequestCount(state, payload) {
    //   state.createdByRequestCount = state.createdByRequestCount - 1
    // },
    selectedApplicationPeriod(state, payload) {
      state.selectedApplicationPeriod = payload
    },
    selectedApplicationPeriodId(state, payload) {
      state.selectedApplicationPeriodId = payload
    },
    periodRequestCount(state, payload) {
      state.periodRequestCount = payload
    },
    increasePeriodRequestCount(state, payload) {
      state.periodRequestCount = state.periodRequestCount + 1
    },
    decreasePeriodRequestCount(state, payload) {
      state.periodRequestCount = state.periodRequestCount - 1
    },
    selectedApplicationTaxableProperty(state, payload) {
      state.selectedApplicationTaxableProperty = payload
    },
    selectedApplicationTaxablePropertyId(state, payload) {
      state.selectedApplicationTaxablePropertyId = payload
    },
    taxablePropertyRequestCount(state, payload) {
      state.taxablePropertyRequestCount = payload
    },
    increaseTaxablePropertyRequestCount(state, payload) {
      state.taxablePropertyRequestCount = state.taxablePropertyRequestCount + 1
    },
    decreaseTaxablePropertyRequestCount(state, payload) {
      state.taxablePropertyRequestCount = state.taxablePropertyRequestCount - 1
    },
    // selectedApplicationUpdatedBy(state, payload) {
    //   state.selectedApplicationUpdatedBy = payload
    // },
    // selectedApplicationUpdatedById(state, payload) {
    //   state.selectedApplicationUpdatedById = payload
    // },
    // updatedByRequestCount(state, payload) {
    //   state.updatedByRequestCount = payload
    // },
    // increaseUpdatedByRequestCount(state, payload) {
    //   state.updatedByRequestCount = state.updatedByRequestCount + 1
    // },
    // decreaseUpdatedByRequestCount(state, payload) {
    //   state.updatedByRequestCount = state.updatedByRequestCount - 1
    // },
    selectedApplicationApplicationDocuments(state, payload) {
      state.selectedApplicationApplicationDocuments = payload
    },
    selectedApplicationApplicationDocumentsId(state, payload) {
      state.selectedApplicationApplicationDocumentsId = payload
    },
    applicationDocumentsRequestCount(state, payload) {
      state.applicationDocumentsRequestCount = payload
    },
    increaseApplicationDocumentsRequestCount(state, payload) {
      state.applicationDocumentsRequestCount =
        state.applicationDocumentsRequestCount + 1
    },
    decreaseApplicationDocumentsRequestCount(state, payload) {
      state.applicationDocumentsRequestCount =
        state.applicationDocumentsRequestCount - 1
    },
    selectedApplicationApplicationNotes(state, payload) {
      state.selectedApplicationApplicationNotes = payload
    },
    selectedApplicationApplicationNotesId(state, payload) {
      state.selectedApplicationApplicationNotesId = payload
    },
    applicationNotesRequestCount(state, payload) {
      state.applicationNotesRequestCount = payload
    },
    increaseApplicationNotesRequestCount(state, payload) {
      state.applicationNotesRequestCount =
        state.applicationNotesRequestCount + 1
    },
    decreaseApplicationNotesRequestCount(state, payload) {
      state.applicationNotesRequestCount =
        state.applicationNotesRequestCount - 1
    },
    selectedApplicationApplicationStatusHistories(state, payload) {
      state.selectedApplicationApplicationStatusHistories = payload
    },
    selectedApplicationApplicationStatusHistoriesId(state, payload) {
      state.selectedApplicationApplicationStatusHistoriesId = payload
    },
    applicationStatusHistoriesRequestCount(state, payload) {
      state.applicationStatusHistoriesRequestCount = payload
    },
    increaseApplicationStatusHistoriesRequestCount(state, payload) {
      state.applicationStatusHistoriesRequestCount =
        state.applicationStatusHistoriesRequestCount + 1
    },
    decreaseApplicationStatusHistoriesRequestCount(state, payload) {
      state.applicationStatusHistoriesRequestCount =
        state.applicationStatusHistoriesRequestCount - 1
    },
    selectedApplicationHouseholdMembers(state, payload) {
      state.selectedApplicationHouseholdMembers = payload
    },
    selectedApplicationHouseholdMembersId(state, payload) {
      state.selectedApplicationHouseholdMembersId = payload
    },
    householdMembersRequestCount(state, payload) {
      state.householdMembersRequestCount = payload
    },
    increaseHouseholdMembersRequestCount(state, payload) {
      state.householdMembersRequestCount =
        state.householdMembersRequestCount + 1
    },
    decreaseHouseholdMembersRequestCount(state, payload) {
      state.householdMembersRequestCount =
        state.householdMembersRequestCount - 1
    },
  },

  actions: {
    // setSelectedApplicationCreatedBy(context, payload) {
    //   context.commit('selectedApplicationCreatedBy', payload)
    // },
    // async loadSelectedApplicationCreatedBy(context, payload) {
    //   if (context.state.selectedApplication && context.state.selectedApplication.Id > 0) {
    //     let selectedId = context.state.selectedApplication.Id
    //     if (context.state.selectedApplication && context.state.selectedApplicationCreatedBy.length > 0 && selectedId != context.state.selectedApplicationCreatedById ) {
    //       context.commit('selectedApplicationCreatedBy', {})
    //     }
    //     try {
    //       const url = 'Application/' + selectedId + '/CreatedBy'
    //       context.commit('increaseCreatedByRequestCount')
    //       let res = await Vue.prototype.$axios.get(url)
    //       context.commit('selectedApplicationCreatedBy', res.data)
    //       context.commit('selectedApplicationCreatedById', selectedId)
    //       context.commit('decreaseCreatedByRequestCount')
    //       return res
    //     } catch (err) {
    //       console.error(err)
    //       context.commit('decreaseCreatedByRequestCount')
    //       context.dispatch('errors/handleError', err, { root: true })
    //       throw err
    //     }
    //   }
    // },
    setSelectedApplicationPeriod(context, payload) {
      context.commit('selectedApplicationPeriod', payload)
    },
    async loadSelectedApplicationPeriod(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        let selectedId = context.state.selectedApplication.Id
        if (
          context.state.selectedApplication &&
          context.state.selectedApplicationPeriod.length > 0 &&
          selectedId != context.state.selectedApplicationPeriodId
        ) {
          context.commit('selectedApplicationPeriod', {})
        }
        try {
          const url = 'Application/' + selectedId + '/Period'
          context.commit('increasePeriodRequestCount')
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedApplicationPeriod', res.data)
          context.commit('selectedApplicationPeriodId', selectedId)
          context.commit('decreasePeriodRequestCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreasePeriodRequestCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    setSelectedApplicationTaxableProperty(context, payload) {
      context.commit('selectedApplicationTaxableProperty', payload)
    },
    async loadSelectedApplicationTaxableProperty(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        let selectedId = context.state.selectedApplication.Id
        if (
          context.state.selectedApplication &&
          context.state.selectedApplicationTaxableProperty.length > 0 &&
          selectedId != context.state.selectedApplicationTaxablePropertyId
        ) {
          context.commit('selectedApplicationTaxableProperty', {})
        }
        try {
          const url = 'Application/' + selectedId + '/TaxableProperty'
          context.commit('increaseTaxablePropertyRequestCount')
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedApplicationTaxableProperty', res.data)
          context.commit('selectedApplicationTaxablePropertyId', selectedId)
          context.commit('decreaseTaxablePropertyRequestCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseTaxablePropertyRequestCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    // setSelectedApplicationUpdatedBy(context, payload) {
    //   context.commit('selectedApplicationUpdatedBy', payload)
    // },
    // async loadSelectedApplicationUpdatedBy(context, payload) {
    //   if (context.state.selectedApplication && context.state.selectedApplication.Id > 0) {
    //     let selectedId = context.state.selectedApplication.Id
    //     if (context.state.selectedApplication && context.state.selectedApplicationUpdatedBy.length > 0 && selectedId != context.state.selectedApplicationUpdatedById ) {
    //       context.commit('selectedApplicationUpdatedBy', {})
    //     }
    //     try {
    //       const url = 'Application/' + selectedId + '/UpdatedBy'
    //       context.commit('increaseUpdatedByRequestCount')
    //       let res = await Vue.prototype.$axios.get(url)
    //       context.commit('selectedApplicationUpdatedBy', res.data)
    //       context.commit('selectedApplicationUpdatedById', selectedId)
    //       context.commit('decreaseUpdatedByRequestCount')
    //       return res
    //     } catch (err) {
    //       console.error(err)
    //       context.commit('decreaseUpdatedByRequestCount')
    //       context.dispatch('errors/handleError', err, { root: true })
    //       throw err
    //     }
    //   }
    // },
    setSelectedApplicationApplicationDocuments(context, payload) {
      context.commit('selectedApplicationApplicationDocuments', payload)
    },
    async loadSelectedApplicationApplicationDocuments(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        let selectedId = context.state.selectedApplication.Id
        if (
          context.state.selectedApplication &&
          context.state.selectedApplicationApplicationDocuments.length > 0 &&
          selectedId != context.state.selectedApplicationApplicationDocumentsId
        ) {
          context.commit('selectedApplicationApplicationDocuments', [])
        }
        try {
          const url = 'Application/' + selectedId + '/ApplicationDocuments'
          context.commit('increaseApplicationDocumentsRequestCount')
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedApplicationApplicationDocuments', res.data)
          context.commit(
            'selectedApplicationApplicationDocumentsId',
            selectedId
          )
          context.commit('decreaseApplicationDocumentsRequestCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseApplicationDocumentsRequestCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    setSelectedApplicationApplicationNotes(context, payload) {
      context.commit('selectedApplicationApplicationNotes', payload)
    },
    async loadSelectedApplicationApplicationNotes(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        let selectedId = context.state.selectedApplication.Id
        if (
          context.state.selectedApplication &&
          context.state.selectedApplicationApplicationNotes.length > 0 &&
          selectedId != context.state.selectedApplicationApplicationNotesId
        ) {
          context.commit('selectedApplicationApplicationNotes', [])
        }
        try {
          const url = 'Application/' + selectedId + '/ApplicationNotes'
          context.commit('increaseApplicationNotesRequestCount')
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedApplicationApplicationNotes', res.data)
          context.commit('selectedApplicationApplicationNotesId', selectedId)
          context.commit('decreaseApplicationNotesRequestCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseApplicationNotesRequestCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    setSelectedApplicationApplicationStatusHistories(context, payload) {
      context.commit('selectedApplicationApplicationStatusHistories', payload)
    },
    async loadSelectedApplicationApplicationStatusHistories(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        let selectedId = context.state.selectedApplication.Id
        if (
          context.state.selectedApplication &&
          context.state.selectedApplicationApplicationStatusHistories.length >
            0 &&
          selectedId !=
            context.state.selectedApplicationApplicationStatusHistoriesId
        ) {
          context.commit('selectedApplicationApplicationStatusHistories', [])
        }
        try {
          const url =
            'Application/' + selectedId + '/ApplicationStatusHistories'
          context.commit('increaseApplicationStatusHistoriesRequestCount')
          let res = await Vue.prototype.$axios.get(url)
          context.commit(
            'selectedApplicationApplicationStatusHistories',
            res.data
          )
          context.commit(
            'selectedApplicationApplicationStatusHistoriesId',
            selectedId
          )
          context.commit('decreaseApplicationStatusHistoriesRequestCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseApplicationStatusHistoriesRequestCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    setSelectedApplicationHouseholdMembers(context, payload) {
      context.commit('selectedApplicationHouseholdMembers', payload)
    },
    async loadSelectedApplicationHouseholdMembers(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        let selectedId = context.state.selectedApplication.Id
        if (
          context.state.selectedApplication &&
          context.state.selectedApplicationHouseholdMembers.length > 0 &&
          selectedId != context.state.selectedApplicationHouseholdMembersId
        ) {
          context.commit('selectedApplicationHouseholdMembers', [])
        }
        try {
          const url = 'Application/' + selectedId + '/HouseholdMembers'
          context.commit('increaseHouseholdMembersRequestCount')
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedApplicationHouseholdMembers', res.data)
          context.commit('selectedApplicationHouseholdMembersId', selectedId)
          context.commit('decreaseHouseholdMembersRequestCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseHouseholdMembersRequestCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
  },
}

export default application
