import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luValidation = {
  state: {
    luValidations: [],
    selectedLuValidation: {},
    selectedLuValidationOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luValidations(state) {
      return state.luValidations
    },
    selectedLuValidation(state) {
      return state.selectedLuValidation
    },
    selectedLuValidationOriginal(state) {
      return state.selectedLuValidationOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luValidations(state, payload) {
      state.luValidations = payload
    },
    selectedLuValidation(state, payload) {
      state.selectedLuValidation = JSON.parse(JSON.stringify(payload))
    },
    selectedLuValidationOriginal(state, payload) {
      state.selectedLuValidationOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuValidation(context, payload) {
      context.commit('selectedLuValidation', payload)
    },
    async loadLuValidations(context, payload) {
      const url = 'LuValidation'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luValidations', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedLuValidation(context, payload) {
      if (
        context.state.selectedLuValidation &&
        context.state.selectedLuValidation.Id >  0 
      ) {
        return await context.dispatch(
          'loadSelectedLuValidation',
          context.state.selectedLuValidation.Id
        )
      }
    },
    async loadSelectedLuValidation(context, payload) {
      if (
        context.state.selectedLuValidation &&
        context.state.selectedLuValidation.Id != payload
      ) {
        context.commit('selectedLuValidation', {})
      }
      const url = 'LuValidation/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuValidation', res.data)
        context.commit('selectedLuValidationOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadLuValidations(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadLuValidations')
    },
    async createLuValidation(context, payload) {
      const url = `LuValidation`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'validation created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuValidations')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuValidation(context, payload) {
      var item = context.state.selectedLuValidation
      if (
        payload.Id &&
        payload.Id == context.state.selectedLuValidationOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuValidationOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuValidation/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'validation updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuValidations')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuValidation(context, payload) {
      const url = `LuValidation/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'validation updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuValidations')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuValidation(context, payload) {
      if (payload.Id >  0 ) {
        return context.dispatch('updateLuValidation', payload)
      } else {
        return context.dispatch('createLuValidation', payload)
      }
    },
    async deleteLuValidation(context, payload) {
      const url = `LuValidation/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'validation deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuValidations')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luValidation
