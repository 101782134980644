import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const contact = {
  state: {
  },

  getters: {

  },

  mutations: {

  },

  actions: {

    async searchExisting (context, payload) {
      const url = `Contact/SearchExisting`
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    }

  },
}

export default contact