import store from '../state/store'

export default [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('./views/home')),
  },
  {
    path: '/about',
    name: 'about',
    //*role restriction example
    // meta: {
    //   authRequired: true,
    //   roles: ['Admin'],
    // },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/about')),
  },
  //{
  // path: '/form',
  // name: 'form',
  // //*role restriction example
  // meta: {
  //   authRequired: true,
  //   roles: ['Consumer', 'Admin'],
  // },

  // // route level code-splitting
  // // this generates a separate chunk (about.[hash].js) for this route
  // // which is lazy-loaded when the route is visited.
  // component: () => lazyLoadView(import('./views/form')),
  //},
  // {
  //   path: '/addProperty',
  //   name: 'addProperty',
  //   //*role restriction example
  //   meta: {
  //     authRequired: true,
  //     roles: ['Consumer', 'Admin'],
  //   },

  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => lazyLoadView(import('./views/addProperty')),
  // },
  {
    path: '/inbox',
    name: 'inbox',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin', 'Staff'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/applicationInbox')),
  },
  {
    path: '/staffForm/:appId?',
    name: 'staffForm',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin', 'Staff'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/StaffForm')),
  },
  {
    path: '/consumerForm/:appId?',
    name: 'consumerForm',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Consumer', 'Admin'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/ConsumerForm')),
  },
  {
    path: '/contactSearch',
    name: 'contactSearch',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin', 'Staff'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/contactSearch')),
  },
  {
    path: '/admin',
    name: 'admin',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/admin')),
  },
  {
    path: '/letters',
    name: 'letters',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin', 'Staff'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/Letters')),
  },
  {
    path: '/reports',
    name: 'reports',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin', 'Staff'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/Reports')),
  },
  {
    path: '/wolfs',
    name: 'wolfs',
    //*role restriction example
    meta: {
      authRequired: true,
      roles: ['Admin'],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/Wolfs')),
  },
  {
    path: '/filingInstructions',
    name: 'filingInstructions',
    //*role restriction example
    // meta: {
    //   authRequired: true,
    //   roles: ['Admin'],
    // },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/filingInstructions')),
  },
  {
    path: '/contactRegistration',
    name: 'contactRegistration',
    //*role restriction example
    // meta: {
    //   authRequired: true,
    //   roles: ['Admin'],
    // },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/contactRegistration')),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      //authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: 'home',
              }
            : { ...routeFrom }
        )
      },
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('./views/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/isLoggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/Register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/Register')),
  },
  {
    path: '/ConfirmEmail',
    name: 'ConfirmEmail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/ConfirmEmail')),
  },
  {
    path: '/WrongEmail',
    name: 'WrongEmail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/WrongEmail')),
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => lazyLoadView(import('./views/ResetPassword')),
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('./views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('./views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('./views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('./views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
