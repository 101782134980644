import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luReason = {
  state: {
    luReasons: [],
    selectedLuReason: {},
    selectedLuReasonOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luReasons(state) {
      return state.luReasons
    },
    selectedLuReason(state) {
      return state.selectedLuReason
    },
    selectedLuReasonOriginal(state) {
      return state.selectedLuReasonOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luReasons(state, payload) {
      state.luReasons = payload
    },
    selectedLuReason(state, payload) {
      state.selectedLuReason = JSON.parse(JSON.stringify(payload))
    },
    selectedLuReasonOriginal(state, payload) {
      state.selectedLuReasonOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuReason(context, payload) {
      context.commit('selectedLuReason', payload)
    },
    async loadLuReasons(context, payload) {
      const url = 'LuReason'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luReasons', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuReason(context, payload) {
      if (context.state.selectedLuReason && context.state.selectedLuReason.Id > 0) {
        context.dispatch('loadSelectedLuReason', context.state.selectedLuReason.Id)

      }
    },
    async loadSelectedLuReason(context, payload) {
      if (context.state.selectedLuReason && context.state.selectedLuReason.Id != payload) {
        context.commit('selectedLuReason', {})
      }
      const url = 'LuReason/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuReason', res.data)
        context.commit('selectedLuReasonOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuReasons(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuReasons')
      }, )
    },
    async createLuReason(context, payload){
      const url = `LuReason`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'reason created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuReasons')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuReason(context, payload){
      var item = context.state.selectedLuReason
      if (payload.Id && 
      payload.Id == context.state.selectedLuReasonOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuReasonOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuReason/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'reason updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuReasons')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuReason(context, payload){
      const url = `LuReason/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'reason updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuReasons')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuReason(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuReason', payload)
      } else {
        return context.dispatch('createLuReason', payload)
      }
    },
    async deleteLuReason(context, payload){
      const url = `LuReason/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'reason deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuReasons')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luReason