import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const nextAvailableStatus = {
  state: {
    nextAvailableStatuses: [],
    selectedNextAvailableStatus: {},
    selectedNextAvailableStatusOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    nextAvailableStatuses(state) {
      return state.nextAvailableStatuses
    },
    selectedNextAvailableStatus(state) {
      return state.selectedNextAvailableStatus
    },
    selectedNextAvailableStatusOriginal(state) {
      return state.selectedNextAvailableStatusOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    nextAvailableStatuses(state, payload) {
      state.nextAvailableStatuses = payload
    },
    selectedNextAvailableStatus(state, payload) {
      state.selectedNextAvailableStatus = JSON.parse(JSON.stringify(payload))
    },
    selectedNextAvailableStatusOriginal(state, payload) {
      state.selectedNextAvailableStatusOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedNextAvailableStatus(context, payload) {
      context.commit('selectedNextAvailableStatus', payload)
    },
    async loadNextAvailableStatuses(context, payload) {
      const url = 'NextAvailableStatus'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('nextAvailableStatuses', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedNextAvailableStatus(context, payload) {
      if (context.state.selectedNextAvailableStatus && context.state.selectedNextAvailableStatus.Id > 0) {
        context.dispatch('loadSelectedNextAvailableStatus', context.state.selectedNextAvailableStatus.Id)

      }
    },
    async loadSelectedNextAvailableStatus(context, payload) {
      if (context.state.selectedNextAvailableStatus && context.state.selectedNextAvailableStatus.Id != payload) {
        context.commit('selectedNextAvailableStatus', {})
      }
      const url = 'NextAvailableStatus/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedNextAvailableStatus', res.data)
        context.commit('selectedNextAvailableStatusOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadNextAvailableStatuses(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadNextAvailableStatuses')
      }, )
    },
    async createNextAvailableStatus(context, payload){
      const url = `NextAvailableStatus`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'next available status created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadNextAvailableStatuses')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchNextAvailableStatus(context, payload){
      var item = context.state.selectedNextAvailableStatus
      if (payload.Id && 
      payload.Id == context.state.selectedNextAvailableStatusOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedNextAvailableStatusOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `NextAvailableStatus/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'next available status updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadNextAvailableStatuses')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateNextAvailableStatus(context, payload){
      const url = `NextAvailableStatus/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'next available status updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadNextAvailableStatuses')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveNextAvailableStatus(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateNextAvailableStatus', payload)
      } else {
        return context.dispatch('createNextAvailableStatus', payload)
      }
    },
    async deleteNextAvailableStatus(context, payload){
      const url = `NextAvailableStatus/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'next available status deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadNextAvailableStatuses')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default nextAvailableStatus