<template>
  <BaseTooltipButton
    v-bind="$attrs"
    class="theme--light v-size--default primary"
    :id="idName"
    @click="generate"
    :icon="icon"
    ><slot>Export</slot>
  </BaseTooltipButton>
</template>

<script>
import XLSX from 'xlsx'
export default {
  props: {
    // Json to download
    data: {
      type: Array,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      default: 'mdi-file-excel',
    },
    summaryData: {
      type: Array,
      required: false,
      default: null,
    },
    showHeaders: {
      type: Boolean,
      required: false,
      default: true,
    },
    // this prop is used to fix the problem with other components that use the
    // variable fields, like vee-validate. exportFields works exactly like fields
    exportFields: {
      type: Object,
      required: false,
    },
    // Use as fallback when the row has no field values
    defaultValue: {
      type: String,
      required: false,
      default: '',
    },
    // filename to export
    name: {
      type: String,
      default: 'data.xlsx',
    },
    fetch: {
      type: Function,
    },
    worksheet: {
      type: String,
      default: 'Sheet1',
    },
    //event before generate was called
    beforeGenerate: {
      type: Function,
    },
  },
  computed: {
    // unique identifier
    idName() {
      var now = new Date().getTime()
      return 'export_' + now
    },

    downloadFields() {
      if (this.exportFields !== undefined) return this.exportFields
    },
  },
  methods: {
    async generate() {
      if (typeof this.beforeGenerate === 'function') {
        await this.beforeGenerate()
      }
      let data = this.data
      if (typeof this.fetch === 'function' || !data) data = await this.fetch()

      if (!data || !data.length) {
        return
      }

      let json = this.getProcessedJson(data, this.downloadFields)
      this.jsonToXlsx(json)
    },
    jsonToXlsx(data) {
      if (this.summaryData) {
        var ws = XLSX.utils.json_to_sheet(this.summaryData, {
          skipHeader: true,
          dateNF: 'YYYY-MM-DD HH:mm:ss',
        })
        XLSX.utils.sheet_add_json(ws, data, {
          origin: this.summaryData.length + 1,
        })
      } else {
        var ws = XLSX.utils.json_to_sheet(data, {
          dateNF: 'YYYY-MM-DD HH:mm:ss',
        })
      }
      let keys = this.getKeys(this.data, this.downloadFields)
      var wscols = []
      for (let label in keys) {
        let property = keys[label]
        if (property.width) {
          wscols.push({ width: property.width })
        } else {
          wscols.push({ wch: label.length })
        }
        let c = Object.keys(keys).findIndex((k) => k == label)
        let sr = 1
        let lr = data.length - 1
        if (this.summaryData) {
          sr = this.summaryData.length + 2
          lr += this.summaryData.length + 1
        }
        lr += 1
        if (property.sum) {
          lr += 1
          let column = String.fromCharCode(65 + c)
          ws[XLSX.utils.encode_cell({ c: c, r: lr })] = {
            f: `SUM(${column}${sr + 1}:${column}${lr})`,
          }
          ws['!ref'] = this.range_add_cell(ws['!ref'], { r: lr, c: c })
        }
        if (property.hasOwnProperty('format')) {
          let format = property.format
          var range = { s: { r: sr, c: c }, e: { r: lr, c: c } }
          for (var R = range.s.r; R <= range.e.r; ++R) {
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })]
              if (!cell) continue
              cell.z = format
            }
          }
        }
        if (property.hasOwnProperty('date') && property.date) {
          var range = { s: { r: sr, c: c }, e: { r: lr, c: c } }
          for (var R = range.s.r; R <= range.e.r; ++R) {
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })]
              if (!cell) continue
              cell.t = 'd'
            }
          }
        }
      }
      ws['!cols'] = wscols
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, this.worksheet)
      XLSX.writeFile(wb, this.name)
    },
    /*
		getProcessedJson
		---------------
		Get only the data to export, if no fields are set return all the data
		*/
    getProcessedJson(data, header) {
      let keys = this.getKeys(data, header)
      let newData = []
      let _self = this
      data.map(function (item, index) {
        let newItem = {}
        for (let label in keys) {
          let property = keys[label]
          newItem[label] = _self.getValue(property, item)
        }
        newData.push(newItem)
      })

      return newData
    },
    getKeys(data, header) {
      if (header) {
        return header
      }

      let keys = {}
      for (let key in data[0]) {
        keys[key] = key
      }
      return keys
    },
    getValue(key, item) {
      const field = typeof key !== 'object' ? key : key.field
      let indexes = typeof field !== 'string' ? [] : field.split('.')
      let value = this.defaultValue

      if (!field) value = item
      else if (indexes.length > 1)
        value = this.getValueFromNestedItem(item, indexes)
      else value = this.parseValue(item[field])

      if (key.hasOwnProperty('callback'))
        value = this.getValueFromCallback(value, key.callback)

      return value
    },
    getValueFromNestedItem(item, indexes) {
      let nestedItem = item
      for (let index of indexes) {
        if (nestedItem) nestedItem = nestedItem[index]
      }
      return this.parseValue(nestedItem)
    },

    getValueFromCallback(item, callback) {
      if (typeof callback !== 'function') return this.defaultValue
      const value = callback(item)
      return this.parseValue(value)
    },
    parseValue(value) {
      return value || value === 0 || typeof value === 'boolean'
        ? value
        : this.defaultValue
    },
    range_add_cell(range, cell) {
      var rng = XLSX.utils.decode_range(range)
      var c = typeof cell == 'string' ? XLSX.utils.decode_cell(cell) : cell
      if (rng.s.r > c.r) rng.s.r = c.r
      if (rng.s.c > c.c) rng.s.c = c.c

      if (rng.e.r < c.r) rng.e.r = c.r
      if (rng.e.c < c.c) rng.e.c = c.c
      return XLSX.utils.encode_range(rng)
    },
  }, // end methods
}
</script>
