import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const wolfsRun = {
  state: {
    wolfsRuns: [],
    selectedWolfsRun: {},
    selectedWolfsRunOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    wolfsRuns(state) {
      return state.wolfsRuns
    },
    selectedWolfsRun(state) {
      return state.selectedWolfsRun
    },
    selectedWolfsRunOriginal(state) {
      return state.selectedWolfsRunOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    wolfsRuns(state, payload) {
      state.wolfsRuns = payload
    },
    selectedWolfsRun(state, payload) {
      state.selectedWolfsRun = JSON.parse(JSON.stringify(payload))
    },
    selectedWolfsRunOriginal(state, payload) {
      state.selectedWolfsRunOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedWolfsRun(context, payload) {
      context.commit('selectedWolfsRun', payload)
    },
    async loadWolfsRuns(context, payload) {
      const url = 'WolfsRun'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('wolfsRuns', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedWolfsRun(context, payload) {
      if (
        context.state.selectedWolfsRun &&
        context.state.selectedWolfsRun.Id > 0
      ) {
        return await context.dispatch(
          'loadSelectedWolfsRun',
          context.state.selectedWolfsRun.Id
        )
      }
    },
    async loadSelectedWolfsRun(context, payload) {
      if (
        context.state.selectedWolfsRun &&
        context.state.selectedWolfsRun.Id != payload
      ) {
        context.commit('selectedWolfsRun', {})
      }
      const url = 'WolfsRun/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedWolfsRun', res.data)
        context.commit('selectedWolfsRunOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadWolfsRuns(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadWolfsRuns')
    },
    async createWolfsRun(context, payload) {
      const url = `WolfsRun`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'wolfs run created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadWolfsRuns')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchWolfsRun(context, payload) {
      var item = context.state.selectedWolfsRun
      if (
        payload.Id &&
        payload.Id == context.state.selectedWolfsRunOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedWolfsRunOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `WolfsRun/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'wolfs run updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadWolfsRuns')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateWolfsRun(context, payload) {
      const url = `WolfsRun/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'wolfs run updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadWolfsRuns')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveWolfsRun(context, payload) {
      if (payload.Id > 0) {
        return context.dispatch('updateWolfsRun', payload)
      } else {
        return context.dispatch('createWolfsRun', payload)
      }
    },
    async deleteWolfsRun(context, payload) {
      const url = `WolfsRun/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'wolfs run deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadWolfsRuns')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default wolfsRun
