import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const vwContactProperty = {
  state: {
    vwContactProperties: [],
    selectedVwContactProperty: {},
    selectedVwContactPropertyOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    vwContactProperties(state) {
      return state.vwContactProperties
    },
    selectedVwContactProperty(state) {
      return state.selectedVwContactProperty
    },
    selectedVwContactPropertyOriginal(state) {
      return state.selectedVwContactPropertyOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    vwContactProperties(state, payload) {
      state.vwContactProperties = payload
    },
    selectedVwContactProperty(state, payload) {
      state.selectedVwContactProperty = JSON.parse(JSON.stringify(payload))
    },
    selectedVwContactPropertyOriginal(state, payload) {
      state.selectedVwContactPropertyOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadVwContactProperties(context, payload) {
      const url = 'VwContactProperty'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('vwContactProperties', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadVwContactProperties(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadVwContactProperties')
      }, )
    },
    async createVwContactProperty(context, payload){
      const url = `VwContactProperty`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'contact property created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadVwContactProperties')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default vwContactProperty