<template>
  <v-tooltip :left="left" :top="top" :bottom="bottom" :right="right">
    <template v-slot:activator="{ on, attrs }">
      <span :class="wrapperClass" v-bind="$attrs">
      <v-badge :value="badge" overlap :color="badgeColor || color + ' darken-3'">
        <v-chip
          v-if="chip"
          :dark="dark"
          v-bind="$attrs"
          v-on="on"
          icon
        >
          {{ leftText }}
          <v-icon
            :color="color"
            v-bind="$attrs"
            :dark="dark"
            :right="!!leftText"
            :left="!!rightText"
            >{{ icon }}</v-icon
          >
          {{ rightText }}
        </v-chip>
        <v-btn
          v-else-if="button"
          :dark="dark"
          :color="color"
          v-bind="$attrs"
          v-on="on"
          @click="handleClick"
          :icon="iconButton && !leftText && !rightText"
        >
          {{ leftText }}
          <v-icon
            v-bind="$attrs"
            :right="!!leftText"
            :left="!!rightText"
            :dark="dark"
            >{{ icon }}</v-icon
          >
          {{ rightText }}
        </v-btn>
        <v-icon v-else :color="color" v-on="on" v-bind="$attrs" :dark="dark">{{
          icon
        }}</v-icon>
        <template v-slot:badge="slotData">
          <slot name="badge" v-bind="slotData"></slot>
        </template>
      </v-badge>
      </span>
    </template>
    <slot></slot>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-circle-medium',
    },
    iconButton: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    chip: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    leftText: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
  },
  components: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    left() {
      return this.position == 'left'
    },
    right() {
      return this.position == 'right'
    },
    top() {
      return this.position == 'top'
    },
    bottom() {
      return this.position == 'bottom'
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>