import Vue from 'vue'
import store from '@state/store'
import getConfig from '@config/appinfo.js'


async function loadGClientScript() {
  return new Promise(function(resolve, reject) {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onreadystatechange = script.onload = function() {
      const interval = setInterval(function() {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          clearInterval(interval)
          if (window.google) {
            resolve(window.google)
          } else {
            reject()
          }
        }
      }, 100)
    }
    script.onerror = function(message, url, line, column, error) {
      reject({ message, url, line, column, error })
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}

class GoogleClientProvider {
  constructor(clientConfig) {
    this.clientConfig = JSON.parse(JSON.stringify(clientConfig))
    this.promise = null
    this.client = null
    this.credentials = null
  }
  async getClientConfig() {
    if (!this.clientConfig.client_id) {
      const config = await getConfig()
      this.clientConfig.client_id = config.gapiConfig.clientId
    }
    return this.clientConfig
  }
  async getClient() {
    if (null !== this.client) {
      return this.client
    }
    if (null === this.promise) {
      let config = await this.getClientConfig()
      this.promise = await loadGClientScript()
      this.clientConfig.callback = this.authCallback.bind(this)
      this.clientConfig.native_callback = this.nativeCallback.bind(this)
      await this.promise.accounts.id.initialize(config)
      this.client = this.promise
      return this.client
    }
  }
  async prompt(callback) {
    var client = await this.getClient()
    try {
      if (client && client.accounts && client.accounts.id) {
        client.accounts.id.prompt(callback)
      }
    } catch (e) {
      console.error('google client not loaded')
    }
  }
  getCredentials() {
    return this.credentials
  }
  // this is the callback that the google client will call with the credentials once the user logs in
  authCallback(credentials) {
    this.credentials = credentials
    store.dispatch('auth/handleGoogleCredentials', credentials.credential)
    return this.credentials
  }
  nativeCallback(credentials) {
    if (credentials) {
      this.credentials = credentials
      store.dispatch('auth/handleUNPWCredentials', credentials)
    }
    return this.credentials
  }
}
const plugin = {
  install: function(Vue, options) {
    const clientProvider = new GoogleClientProvider({
      auto_select: true,
      cancel_on_tap_outside: true,
    })
    Vue.google = clientProvider
    Vue.prototype.$google = clientProvider
    Vue.prototype.google = clientProvider
  },
}
Vue.use(plugin)
export default plugin
