import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'
import dayjs from 'dayjs'

const luIncomeThreshold = {
  state: { currentLuIncomeThresholds: [], errorMessage: '' },
  getters: {
    currentLuIncomeThresholds(state) {
      return state.currentLuIncomeThresholds
    },
    errorMessage(state) {
      return state.errorMessage
    },
  },
  mutations: {
    currentLuIncomeThresholds(state, payload) {
      state.currentLuIncomeThresholds = JSON.parse(JSON.stringify(payload))
    },
    errorMessage(state, payload) {
      state.errorMessage = JSON.parse(JSON.stringify(payload))
    },
  },
  actions: {
    async loadCurrentLuIncomeThresholds(context, payload) {
      const url = 'LuIncomeThresholdPublic'
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('currentLuIncomeThresholds', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        if (err.response.status == 404) {
        } else {
          context.dispatch('errors/handleError', err, { root: true })
        }

        throw err
      }
    },
  },
}

export default luIncomeThreshold
