import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const applicationNote = {
  state: {
    applicationNotes: [],
    selectedApplicationNote: {},
    selectedApplicationNoteOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    applicationNotes(state) {
      return state.applicationNotes
    },
    selectedApplicationNote(state) {
      return state.selectedApplicationNote
    },
    selectedApplicationNoteOriginal(state) {
      return state.selectedApplicationNoteOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    applicationNotes(state, payload) {
      state.applicationNotes = payload
    },
    selectedApplicationNote(state, payload) {
      state.selectedApplicationNote = JSON.parse(JSON.stringify(payload))
    },
    selectedApplicationNoteOriginal(state, payload) {
      state.selectedApplicationNoteOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedApplicationNote(context, payload) {
      context.commit('selectedApplicationNote', payload)
    },
    async loadApplicationNotes(context, payload) {
      const url = 'ApplicationNote'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('applicationNotes', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedApplicationNote(context, payload) {
      if (context.state.selectedApplicationNote && context.state.selectedApplicationNote.Id > 0) {
        context.dispatch('loadSelectedApplicationNote', context.state.selectedApplicationNote.Id)

      }
    },
    async loadSelectedApplicationNote(context, payload) {
      if (context.state.selectedApplicationNote && context.state.selectedApplicationNote.Id != payload) {
        context.commit('selectedApplicationNote', {})
      }
      const url = 'ApplicationNote/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedApplicationNote', res.data)
        context.commit('selectedApplicationNoteOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadApplicationNotes(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadApplicationNotes')
      }, )
    },
    async createApplicationNote(context, payload){
      const url = `ApplicationNote`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'application note created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadApplicationNotes')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchApplicationNote(context, payload){
      var item = context.state.selectedApplicationNote
      if (payload.Id && 
      payload.Id == context.state.selectedApplicationNoteOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedApplicationNoteOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `ApplicationNote/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'application note updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadApplicationNotes')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateApplicationNote(context, payload){
      const url = `ApplicationNote/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'application note updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadApplicationNotes')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveApplicationNote(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateApplicationNote', payload)
      } else {
        return context.dispatch('createApplicationNote', payload)
      }
    },
    async deleteApplicationNote(context, payload){
      const url = `ApplicationNote/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'application note deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadApplicationNotes')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default applicationNote