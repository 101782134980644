<template>
  <div>
    <v-dialog persistent v-model="dialog" scrollable width="850">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          <span>
            <slot name="header">Confirm?</slot>
          </span>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12"
              ><span>
                <slot :selected="selected"> Are you sure? </slot>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" outlined @click="dialog = !dialog">{{
            closeText
          }}</v-btn>
          <v-btn @click="callConfirm" color="primary">{{ confirmText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    confirm: {
      type: Function,
      required: true,
    },
    closeText: {
      type: String,
      default: 'Close',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
  },
  data() {
    return {
      selected: {},
      dialog: false,
    }
  },
  created() {},
  computed: {},
  methods: {
    confirmItem(item) {
      this.selected = item
      this.dialog = true
    },
    async callConfirm() {
      try {
        await this.confirm(this.selected)
        this.$emit('refresh')
        this.dialog = false
      } catch (err) {
        this.$emit('error', err)
      }
    },
  },
}
</script>
<style scoped></style>
