import Vue from 'vue'

const fundingAppropriation = {
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async recalculateFundingAppropriation(context, payload) {
      const url = 'FundingAppropriation/Recalculate/' + payload
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.dispatch(
          'messages/toastMessage',
          {
            color: 'success',
            message: 'Funding Appropriation Refreshed',
          },
          { root: true }
        )
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async getFundingAppropriationByFundingSourceAndPeriod(context, payload) {
      const url = `FundingAppropriation/ByFundingSourceAndPeriod?fundingSourceId=${payload.fundingSourceId}&periodId=${payload.periodId}`
      try {
        let res = await Vue.prototype.$axios.get(url)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default fundingAppropriation
