<template>
  <div class="stickyBanner">
    <slot></slot>
  </div>
</template>
<script>
export default {
  //model: {
  //	prop: ''
  //},
  components: {},
  data() {
    return {}
  },
}
</script>
<style scoped>
.stickyBanner {
  border: 1px solid var(--v-primary-darken2);
  background-color: var(--v-accent-lighten1);
  position: sticky;
  top: 4rem;
  z-index: 2;
}
div {
  color: var(--v-primary-base);
}
</style>
