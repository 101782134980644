import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const user = {
  state: {
    users: [],
    selectedUser: {},
    selectedUserOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    users(state) {
      return state.users
    },
    selectedUser(state) {
      return state.selectedUser
    },
    selectedUserOriginal(state) {
      return state.selectedUserOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    users(state, payload) {
      state.users = payload
    },
    selectedUser(state, payload) {
      state.selectedUser = JSON.parse(JSON.stringify(payload))
    },
    selectedUserOriginal(state, payload) {
      state.selectedUserOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedUser(context, payload) {
      context.commit('selectedUser', payload)
    },
    async loadUsers(context, payload) {
      const url = 'User'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('users', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedUser(context, payload) {
      if (context.state.selectedUser && context.state.selectedUser.Id > 0) {
        context.dispatch('loadSelectedUser', context.state.selectedUser.Id)

      }
    },
    async loadSelectedUser(context, payload) {
      if (context.state.selectedUser && context.state.selectedUser.Id != payload) {
        context.commit('selectedUser', {})
      }
      const url = 'User/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedUser', res.data)
        context.commit('selectedUserOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadUsers(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadUsers')
      }, )
    },
    async createUser(context, payload){
      const url = `User`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'user created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadUsers')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchUser(context, payload){
      var item = context.state.selectedUser
      if (payload.Id && 
      payload.Id == context.state.selectedUserOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedUserOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `User/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'user updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadUsers')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateUser(context, payload){
      const url = `User/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'user updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadUsers')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveUser(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateUser', payload)
      } else {
        return context.dispatch('createUser', payload)
      }
    },
    async deleteUser(context, payload){
      const url = `User/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'user deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadUsers')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default user