import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luDocumentType = {
  state: {
    luDocumentTypes: [],
    selectedLuDocumentType: {},
    selectedLuDocumentTypeOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luDocumentTypes(state) {
      return state.luDocumentTypes
    },
    selectedLuDocumentType(state) {
      return state.selectedLuDocumentType
    },
    selectedLuDocumentTypeOriginal(state) {
      return state.selectedLuDocumentTypeOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luDocumentTypes(state, payload) {
      state.luDocumentTypes = payload
    },
    selectedLuDocumentType(state, payload) {
      state.selectedLuDocumentType = JSON.parse(JSON.stringify(payload))
    },
    selectedLuDocumentTypeOriginal(state, payload) {
      state.selectedLuDocumentTypeOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuDocumentType(context, payload) {
      context.commit('selectedLuDocumentType', payload)
    },
    async loadLuDocumentTypes(context, payload) {
      const url = 'LuDocumentType'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luDocumentTypes', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuDocumentType(context, payload) {
      if (context.state.selectedLuDocumentType && context.state.selectedLuDocumentType.Id > 0) {
        context.dispatch('loadSelectedLuDocumentType', context.state.selectedLuDocumentType.Id)

      }
    },
    async loadSelectedLuDocumentType(context, payload) {
      if (context.state.selectedLuDocumentType && context.state.selectedLuDocumentType.Id != payload) {
        context.commit('selectedLuDocumentType', {})
      }
      const url = 'LuDocumentType/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuDocumentType', res.data)
        context.commit('selectedLuDocumentTypeOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuDocumentTypes(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuDocumentTypes')
      }, )
    },
    async createLuDocumentType(context, payload){
      const url = `LuDocumentType`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'document type created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuDocumentTypes')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuDocumentType(context, payload){
      var item = context.state.selectedLuDocumentType
      if (payload.Id && 
      payload.Id == context.state.selectedLuDocumentTypeOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuDocumentTypeOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuDocumentType/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'document type updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuDocumentTypes')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuDocumentType(context, payload){
      const url = `LuDocumentType/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'document type updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuDocumentTypes')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuDocumentType(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuDocumentType', payload)
      } else {
        return context.dispatch('createLuDocumentType', payload)
      }
    },
    async deleteLuDocumentType(context, payload){
      const url = `LuDocumentType/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'document type deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuDocumentTypes')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luDocumentType