import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const userRole = {
  state: {
    userRoles: [],
    selectedUserRole: {},
    selectedUserRoleOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    userRoles(state) {
      return state.userRoles
    },
    selectedUserRole(state) {
      return state.selectedUserRole
    },
    selectedUserRoleOriginal(state) {
      return state.selectedUserRoleOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    userRoles(state, payload) {
      state.userRoles = payload
    },
    selectedUserRole(state, payload) {
      state.selectedUserRole = JSON.parse(JSON.stringify(payload))
    },
    selectedUserRoleOriginal(state, payload) {
      state.selectedUserRoleOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadUserRoles(context, payload) {
      const url = 'UserRole'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('userRoles', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedUserRole(context, payload) {
      if (context.state.selectedUserRole && 
        context.state.selectedUserRole.UserId > 0 &&
              context.state.selectedUserRole.RoleId > 0 
      ) {
      let keys = {
      userId:  context.state.selectedUserRole.UserId,
      roleId:  context.state.selectedUserRole.RoleId,
      }
        context.dispatch('loadSelectedUserRole', keys)
      }
    },
    async loadSelectedUserRole(context, payload) {
      if (
      payload &&
      payload.userId > 0 &&
            payload.roleId > 0
      ) {
      if (context.state.selectedUserRole && (
      context.state.selectedUserRole.UserId != payload.userId || 
            context.state.selectedUserRole.RoleId != payload.roleId 
      )) {
        context.commit('selectedUserRole', {})
        context.commit('selectedUserRoleOriginal', {})
      }
        const url = 'UserRole/'
        payload.userId+ '/' +
        payload.roleId
        context.commit('increaseSearchCount')
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedUserRole', res.data)
          context.commit('selectedUserRoleOriginal', res.data)
          context.commit('decreaseSearchCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseSearchCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    clearLookupCacheAndReloadUserRoles(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadUserRoles')
      }, )
    },
    async createUserRole(context, payload){
      const url = `UserRole`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'user role created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadUserRoles')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchUserRole(context, payload){ 
      var item = context.state.selectedUserRole
      if (
      payload.UserId && payload.UserId == context.state.selectedUserRoleOriginal.UserId && 
      payload.RoleId && payload.RoleId == context.state.selectedUserRoleOriginal.RoleId
      )  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc ||!payload.UserId || 
    !payload.RoleId
    ) {
        let original = context.state.selectedUserRoleOriginal
        let current = item
        patchPayload.UserId = item.UserId
        patchPayload.RoleId = item.RoleId
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
      const url = 'UserRole?'+
      'userId='+   patchPayload.UserId+ '&' +
      'roleId='+   patchPayload.RoleId
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
            .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'user role updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadUserRoles')
          context.commit('saving', false)
          return res
        }catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateUserRole(context, payload){
      const url = 'UserRole?'+
      'userId='+   payload.UserId+ '&' +
      'roleId='+   payload.RoleId
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
        .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'user role updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadUserRoles')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async deleteUserRole(context, payload){
        const url = 'UserRole?'+
        'userId='+   payload.UserId+ '&' +
        'roleId='+   payload.RoleId
      try {
        let res = await Vue.prototype.$axios
        .delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'user role deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadUserRoles')
        return res
      }catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default userRole