import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luGlobal = {
  state: {
    luGlobals: [],
    selectedLuGlobal: {},
    selectedLuGlobalOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luGlobals(state) {
      return state.luGlobals
    },
    selectedLuGlobal(state) {
      return state.selectedLuGlobal
    },
    selectedLuGlobalOriginal(state) {
      return state.selectedLuGlobalOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luGlobals(state, payload) {
      state.luGlobals = payload
    },
    selectedLuGlobal(state, payload) {
      state.selectedLuGlobal = JSON.parse(JSON.stringify(payload))
    },
    selectedLuGlobalOriginal(state, payload) {
      state.selectedLuGlobalOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuGlobal(context, payload) {
      context.commit('selectedLuGlobal', payload)
    },
    async loadLuGlobals(context, payload) {
      const url = 'LuGlobal'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luGlobals', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuGlobal(context, payload) {
      if (context.state.selectedLuGlobal && context.state.selectedLuGlobal.Id > 0) {
        context.dispatch('loadSelectedLuGlobal', context.state.selectedLuGlobal.Id)

      }
    },
    async loadSelectedLuGlobal(context, payload) {
      if (context.state.selectedLuGlobal && context.state.selectedLuGlobal.Id != payload) {
        context.commit('selectedLuGlobal', {})
      }
      const url = 'LuGlobal/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuGlobal', res.data)
        context.commit('selectedLuGlobalOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuGlobals(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuGlobals')
      }, )
    },
    async createLuGlobal(context, payload){
      const url = `LuGlobal`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'global created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuGlobals')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuGlobal(context, payload){
      var item = context.state.selectedLuGlobal
      if (payload.Id && 
      payload.Id == context.state.selectedLuGlobalOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuGlobalOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuGlobal/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'global updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuGlobals')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuGlobal(context, payload){
      const url = `LuGlobal/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'global updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuGlobals')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuGlobal(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuGlobal', payload)
      } else {
        return context.dispatch('createLuGlobal', payload)
      }
    },
    async deleteLuGlobal(context, payload){
      const url = `LuGlobal/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'global deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuGlobals')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luGlobal