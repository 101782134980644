import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luCity = {
  state: {
    luCities: [],
    selectedLuCity: {},
    selectedLuCityOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luCities(state) {
      return state.luCities
    },
    selectedLuCity(state) {
      return state.selectedLuCity
    },
    selectedLuCityOriginal(state) {
      return state.selectedLuCityOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luCities(state, payload) {
      state.luCities = payload
    },
    selectedLuCity(state, payload) {
      state.selectedLuCity = JSON.parse(JSON.stringify(payload))
    },
    selectedLuCityOriginal(state, payload) {
      state.selectedLuCityOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuCity(context, payload) {
      context.commit('selectedLuCity', payload)
    },
    async loadLuCities(context, payload) {
      const url = 'LuCity'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luCities', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuCity(context, payload) {
      if (context.state.selectedLuCity && context.state.selectedLuCity.Id > 0) {
        context.dispatch('loadSelectedLuCity', context.state.selectedLuCity.Id)

      }
    },
    async loadSelectedLuCity(context, payload) {
      if (context.state.selectedLuCity && context.state.selectedLuCity.Id != payload) {
        context.commit('selectedLuCity', {})
      }
      const url = 'LuCity/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuCity', res.data)
        context.commit('selectedLuCityOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuCities(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuCities')
      }, )
    },
    async createLuCity(context, payload){
      const url = `LuCity`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'city created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuCities')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuCity(context, payload){
      var item = context.state.selectedLuCity
      if (payload.Id && 
      payload.Id == context.state.selectedLuCityOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuCityOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuCity/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'city updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuCities')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuCity(context, payload){
      const url = `LuCity/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'city updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuCities')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuCity(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuCity', payload)
      } else {
        return context.dispatch('createLuCity', payload)
      }
    },
    async deleteLuCity(context, payload){
      const url = `LuCity/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'city deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuCities')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luCity