import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const availableStatusRole = {
  state: {
    availableStatusRoles: [],
    selectedAvailableStatusRole: {},
    selectedAvailableStatusRoleOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    availableStatusRoles(state) {
      return state.availableStatusRoles
    },
    selectedAvailableStatusRole(state) {
      return state.selectedAvailableStatusRole
    },
    selectedAvailableStatusRoleOriginal(state) {
      return state.selectedAvailableStatusRoleOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    availableStatusRoles(state, payload) {
      state.availableStatusRoles = payload
    },
    selectedAvailableStatusRole(state, payload) {
      state.selectedAvailableStatusRole = JSON.parse(JSON.stringify(payload))
    },
    selectedAvailableStatusRoleOriginal(state, payload) {
      state.selectedAvailableStatusRoleOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadAvailableStatusRoles(context, payload) {
      const url = 'AvailableStatusRole'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('availableStatusRoles', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedAvailableStatusRole(context, payload) {
      if (context.state.selectedAvailableStatusRole && 
        context.state.selectedAvailableStatusRole.AvailableStatusId > 0 &&
              context.state.selectedAvailableStatusRole.RoleId > 0 
      ) {
      let keys = {
      availableStatusId:  context.state.selectedAvailableStatusRole.AvailableStatusId,
      roleId:  context.state.selectedAvailableStatusRole.RoleId,
      }
        context.dispatch('loadSelectedAvailableStatusRole', keys)
      }
    },
    async loadSelectedAvailableStatusRole(context, payload) {
      if (
      payload &&
      payload.availableStatusId > 0 &&
            payload.roleId > 0
      ) {
      if (context.state.selectedAvailableStatusRole && (
      context.state.selectedAvailableStatusRole.AvailableStatusId != payload.availableStatusId || 
            context.state.selectedAvailableStatusRole.RoleId != payload.roleId 
      )) {
        context.commit('selectedAvailableStatusRole', {})
        context.commit('selectedAvailableStatusRoleOriginal', {})
      }
        const url = 'AvailableStatusRole/'
        payload.availableStatusId+ '/' +
        payload.roleId
        context.commit('increaseSearchCount')
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedAvailableStatusRole', res.data)
          context.commit('selectedAvailableStatusRoleOriginal', res.data)
          context.commit('decreaseSearchCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseSearchCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    clearLookupCacheAndReloadAvailableStatusRoles(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadAvailableStatusRoles')
      }, )
    },
    async createAvailableStatusRole(context, payload){
      const url = `AvailableStatusRole`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'available status role created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadAvailableStatusRoles')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchAvailableStatusRole(context, payload){ 
      var item = context.state.selectedAvailableStatusRole
      if (
      payload.AvailableStatusId && payload.AvailableStatusId == context.state.selectedAvailableStatusRoleOriginal.AvailableStatusId && 
      payload.RoleId && payload.RoleId == context.state.selectedAvailableStatusRoleOriginal.RoleId
      )  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc ||!payload.AvailableStatusId || 
    !payload.RoleId
    ) {
        let original = context.state.selectedAvailableStatusRoleOriginal
        let current = item
        patchPayload.AvailableStatusId = item.AvailableStatusId
        patchPayload.RoleId = item.RoleId
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
      const url = 'AvailableStatusRole?'+
      'availableStatusId='+   patchPayload.AvailableStatusId+ '&' +
      'roleId='+   patchPayload.RoleId
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
            .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'available status role updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadAvailableStatusRoles')
          context.commit('saving', false)
          return res
        }catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateAvailableStatusRole(context, payload){
      const url = 'AvailableStatusRole?'+
      'availableStatusId='+   payload.AvailableStatusId+ '&' +
      'roleId='+   payload.RoleId
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
        .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'available status role updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadAvailableStatusRoles')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async deleteAvailableStatusRole(context, payload){
        const url = 'AvailableStatusRole?'+
        'availableStatusId='+   payload.AvailableStatusId+ '&' +
        'roleId='+   payload.RoleId
      try {
        let res = await Vue.prototype.$axios
        .delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'available status role deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadAvailableStatusRoles')
        return res
      }catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default availableStatusRole