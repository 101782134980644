import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const cannedReport = {
  state: {
    cannedReport: [],
    selectedCannedReport: {},
    selectedCannedReportOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    cannedReport(state) {
      return state.cannedReport
    },
    selectedCannedReport(state) {
      return state.selectedCannedReport
    },
    selectedCannedReportOriginal(state) {
      return state.selectedCannedReportOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    cannedReport(state, payload) {
      state.cannedReport = payload
    },
    selectedCannedReport(state, payload) {
      state.selectedCannedReport = JSON.parse(JSON.stringify(payload))
    },
    selectedCannedReportOriginal(state, payload) {
      state.selectedCannedReportOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedCannedReport(context, payload) {
      context.commit('resetSearchParamsAndData')
      context.dispatch('loadBounce', context)
      context.commit('selectedCannedReport', payload)
    },
    loadCannedReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'CannedReport'
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('cannedReport', res.data)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    refreshSelectedCannedReport(context, payload) {
      if (
        context.state.selectedCannedReport &&
        context.state.selectedCannedReport.Id > 0
      ) {
        context.dispatch(
          'loadSelectedCannedReport',
          context.state.selectedCannedReport.Id
        )
      }
    },
    loadSelectedCannedReport(context, payload) {
      if (
        context.state.selectedCannedReport &&
        context.state.selectedCannedReport.Id != payload
      ) {
        context.commit('selectedCannedReport', {})
      }
      return new Promise((resolve, reject) => {
        const url = 'CannedReport/' + payload
        context.commit('increaseSearchCount')
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.commit('selectedCannedReport', res.data)
            context.commit('selectedCannedReportOriginal', res.data)
            context.commit('decreaseSearchCount')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('decreaseSearchCount')
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    clearLookupCacheAndReloadCannedReport(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
        context.dispatch('loadCannedReport')
      })
    },
    createCannedReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `CannedReport`
        context.commit('saving', true)
        Vue.prototype.$axios.post(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'canned report created'},
              {root: true}
            )
            //if you will always need to reload the list after an item is created
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadCannedReport')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    patchCannedReport(context, payload) {
      return new Promise((resolve, reject) => {
        var item = context.state.selectedCannedReport
        if (
          payload.Id &&
          payload.Id == context.state.selectedCannedReportOriginal.Id
        ) {
          item = payload
        }
        var patchPayload = {}
        if (!payload.patchDoc || !payload.Id) {
          let original = context.state.selectedCannedReportOriginal
          let current = item
          patchPayload.Id = item.Id
          var diff = jsonpatch.compare(original, current)
          patchPayload.patchDoc = diff
        } else {
          patchPayload = payload
        }
        if (patchPayload.patchDoc.length > 0) {
          const url = `CannedReport/${patchPayload.Id}`
          context.commit('saving', true)
          Vue.prototype.$axios.patch(url, patchPayload.patchDoc).then(
            (res) => {
              context.dispatch(
                'messages/toastMessage',
                {color: 'success', message: 'canned report updated'},
                {root: true}
              )
              //if you will always need to reload the list after an update
              //you can uncomment out the following dispatch line.
              //Alternatively you can reload whatever object you need
              //when the promise resolves in the component that called it.
              //context.dispatch('loadCannedReport')
              context.commit('saving', false)
              resolve(res)
            },
            (err) => {
              console.error(err)
              context.commit('saving', false)
              context.dispatch('errors/handleError', err, {root: true})
              reject(err)
            }
          )
        } else {
          resolve('no changes')
        }
      })
    },
    updateCannedReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `CannedReport/${payload.Id}`
        context.commit('saving', true)
        Vue.prototype.$axios.put(url, payload).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'canned report updated'},
              {root: true}
            )
            //if you will always need to reload the list after an update
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadCannedReport')
            context.commit('saving', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.commit('saving', false)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
    saveCannedReport(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.Id > 0) {
          context.dispatch('updateCannedReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        } else {
          context.dispatch('createCannedReport', payload).then(
            (res) => resolve(res),
            (err) => reject(err)
          )
        }
      })
    },
    deleteCannedReport(context, payload) {
      return new Promise((resolve, reject) => {
        const url = `CannedReport/${payload.Id}`
        Vue.prototype.$axios.delete(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {color: 'success', message: 'canned report deleted'},
              {root: true}
            )
            //if you will always need to reload the list after a delete
            //you can uncomment out the following dispatch line.
            //Alternatively you can reload whatever object you need
            //when the promise resolves in the component that called it.
            //context.dispatch('loadCannedReport')
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, {root: true})
            reject(err)
          }
        )
      })
    },
  },
}

export default cannedReport
