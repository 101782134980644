<!-- BODY LAYOUT -->
<template>
  <div>
    <NavBar />
    <ErrorDisplay />
    <ToastDisplay />
    <MessageDialog />
    <slot />
  </div>
</template>


<!-- SCRIPTS -->
<script>
import NavBar from '@components/nav-bar' /*  */
import ErrorDisplay from '@components/error-display' /*  */
import ToastDisplay from '@components/toast-display' /*  */
import MessageDialog from '@components/message-dialog'

export default {
  components: { NavBar, ErrorDisplay, ToastDisplay, MessageDialog },
}
</script>

<!-- STYLES -->
<style>
</style>



