<template>
  <span>
    <div ref="google_one_tap"></div>
  </span>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    alwaysShowButton: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.$google.getClient()
    var buttonDiv = this.$refs.google_one_tap
    var theme = this.$vuetify.theme.dark ? 'filled_black' : 'outline'
    this.$google.client.accounts.id.renderButton(buttonDiv, {
      type: 'standard',
      shape: 'rectangular',
      theme,
      size: 'large',
      logo_alignment: 'left',
      width: 350,
    })
  },
  computed: {},
  methods: {},
}
</script>

<style scoped></style>
