import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luCounty = {
  state: {
    luCounties: [],
    selectedLuCounty: {},
    selectedLuCountyOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luCounties(state) {
      return state.luCounties
    },
    selectedLuCounty(state) {
      return state.selectedLuCounty
    },
    selectedLuCountyOriginal(state) {
      return state.selectedLuCountyOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luCounties(state, payload) {
      state.luCounties = payload
    },
    selectedLuCounty(state, payload) {
      state.selectedLuCounty = JSON.parse(JSON.stringify(payload))
    },
    selectedLuCountyOriginal(state, payload) {
      state.selectedLuCountyOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuCounty(context, payload) {
      context.commit('selectedLuCounty', payload)
    },
    async loadLuCounties(context, payload) {
      const url = 'LuCounty'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luCounties', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuCounty(context, payload) {
      if (context.state.selectedLuCounty && context.state.selectedLuCounty.Id > 0) {
        context.dispatch('loadSelectedLuCounty', context.state.selectedLuCounty.Id)

      }
    },
    async loadSelectedLuCounty(context, payload) {
      if (context.state.selectedLuCounty && context.state.selectedLuCounty.Id != payload) {
        context.commit('selectedLuCounty', {})
      }
      const url = 'LuCounty/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuCounty', res.data)
        context.commit('selectedLuCountyOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuCounties(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuCounties')
      }, )
    },
    async createLuCounty(context, payload){
      const url = `LuCounty`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'county created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuCounties')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuCounty(context, payload){
      var item = context.state.selectedLuCounty
      if (payload.Id && 
      payload.Id == context.state.selectedLuCountyOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuCountyOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuCounty/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'county updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuCounties')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuCounty(context, payload){
      const url = `LuCounty/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'county updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuCounties')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuCounty(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuCounty', payload)
      } else {
        return context.dispatch('createLuCounty', payload)
      }
    },
    async deleteLuCounty(context, payload){
      const url = `LuCounty/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'county deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuCounties')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luCounty