import Vue from 'vue'
import { debounce, range } from 'lodash'
import download from 'downloadjs'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const adhocSortByDefault = 'ApplicationId'
const vwApplicationReport = {
  state: {
    adhocVwApplicationReports: [],
    adhocTotal: 0,
    adhocPage: 1,
    adhocSortBy: [adhocSortByDefault],
    adhocDescending: [true],
    adhocGroupBy: null,
    adhocGroupDescending: null,
    adhocRowsPerPage: 25,
    adhocSearchCount: 0,
    adhocPivotIncludePivot: false,
    adhocPivotFirst: true,
    adhocPivotName: '',
    adhocPivotRowFields: [],
    adhocPivotColumnFields: [],
    adhocPivotExcludeFields: [],
    adhocPivotDataFields: [],
    adhocPivotFilterFields: [],
    adhocSelectedFields: [
      'ApplicationNumber',
      'StatusCode',
      'CurrentStatusDate',
      'ReasonNumber',
      'ReasonDescription',
      'ReasonType',
      'County',
      'BudgetFiscalYear',
      'FirstName',
      'MiddleInitial',
      'LastName',
      'ParcelId',
      'StreetAddress1',
      'StreetAddress2',
      'PropertyCity',
      'PropertyState',
      'PropertyZip',
      'TotalHouseholdIncomeReported',
      'ThresholdType',
      'ThresholdMaximumIncome',
      'ThresholdIncomePercentage',
      'ThresholdRefundPercentage',
      'HouseholdIncomePercentage',
      'ApplicationApproveAmount',
      'WolfsPaidAmount',
    ],
    adhocQueryIsModified: false,
    adhocQueryName: '',
    adhocText: '',
    adhocStartDate: null, //dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
    adhocEndDate: null, //dayjs().format('YYYY-MM-DD'),
    //templateTODO: uncomment as used in AdHocVwApplicationReportReportParams
    // adhocApplicationId: [],
    // adhocApplicationNumber: [],
    adhocStatusId: [],
    // adhocStatusCode: [],
    adhocReasonId: [],
    // adhocReasonDescription: [],
    // adhocReasonNumber: [],
    // adhocReasonType: [],
    adhocCountyId: [],
    // adhocCityId: [],
    // adhocCounty: [],
    adhocPeriodId: [],
    // adhocFirstName: [],
    // adhocMiddleInitial: [],
    // adhocLastName: [],
    // adhocMailingAddress1: [],
    // adhocMailingAddress2: [],
    // adhocMailingCity: [],
    // adhocMailingState: [],
    // adhocMailingZip: [],
    // adhocParcelId: [],
    // adhocStreetAddress1: [],
    // adhocStreetAddress2: [],
    // adhocPropertyCity: [],
    // adhocPropertyState: [],
    // adhocPropertyZip: [],
  },

  getters: {
    adhocParameters(state) {
      var sortDirection
      if (Array.isArray(state.adhocDescending)) {
        sortDirection = state.adhocDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.adhocDescending ? ['desc'] : ['asc']
      }
      var groupByDirection
      if (Array.isArray(state.adhocGroupDescending)) {
        if (state.adhocGroupDescending.length > 0) {
          groupByDirection = state.adhocGroupDescending[0] ? 'desc' : 'asc'
        } else {
          groupByDirection = 'asc'
        }
      } else {
        groupByDirection = state.adhocGroupDescending ? 'desc' : 'asc'
      }
      return {
        pageNum: state.adhocPage - 1,
        pageSize: state.adhocRowsPerPage,
        sortProperty: state.adhocSortBy,
        sortDirection,
        groupByProperty: state.adhocGroupBy,
        groupByDirection,
        searchText: state.adhocText,
        includeFields: state.adhocSelectedFields,
        queryName: state.adhocQueryName,
        queryIsModified: state.adhocQueryIsModified,
        pivotOptions: {
          includePivot: state.adhocPivotIncludePivot,
          pivotFirst: state.adhocPivotFirst,
          pivotName: state.adhocPivotName,
          rowFields: state.adhocPivotRowFields,
          columnFields: state.adhocPivotColumnFields,
          dataFields: state.adhocPivotDataFields,
          excludeFields: state.adhocPivotExcludeFields,
          filterFields: state.adhocPivotFilterFields,
        },
        startDate: state.adhocStartDate,
        endDate: state.adhocEndDate,
        //templateTODO: uncomment as used in AdHocVwApplicationReportReportParams
        // applicationId: state.adhocApplicationId,
        // applicationNumber: state.adhocApplicationNumber,
        statusId: state.adhocStatusId,
        // statusCode: state.adhocStatusCode,
        reasonId: state.adhocReasonId,
        // reasonDescription: state.adhocReasonDescription,
        // reasonNumber: state.adhocReasonNumber,
        // reasonType: state.adhocReasonType,
        countyId: state.adhocCountyId,
        // cityId: state.adhocCityId,
        // county: state.adhocCounty,
        periodId: state.adhocPeriodId,
        // firstName: state.adhocFirstName,
        // middleInitial: state.adhocMiddleInitial,
        // lastName: state.adhocLastName,
        // mailingAddress1: state.adhocMailingAddress1,
        // mailingAddress2: state.adhocMailingAddress2,
        // mailingCity: state.adhocMailingCity,
        // mailingState: state.adhocMailingState,
        // mailingZip: state.adhocMailingZip,
        // parcelId: state.adhocParcelId,
        // streetAddress1: state.adhocStreetAddress1,
        // streetAddress2: state.adhocStreetAddress2,
        // propertyCity: state.adhocPropertyCity,
        // propertyState: state.adhocPropertyState,
        // propertyZip: state.adhocPropertyZip,
      }
    },
    adhocLoading(state) {
      return state.adhocSearchCount > 0
    },
  },

  mutations: {
    adhocVwApplicationReports(state, payload) {
      state.adhocVwApplicationReports = payload
    },
    adhocTotal(state, payload) {
      state.adhocTotal = payload
    },
    adhocPage(state, payload) {
      state.adhocPage = payload
    },
    adhocSortBy(state, payload) {
      state.adhocSortBy = payload || [adhocSortByDefault]
    },
    adhocDescending(state, payload) {
      state.adhocDescending = payload
    },
    adhocGroupBy(state, payload) {
      state.adhocGroupBy = payload
    },
    adhocGroupDescending(state, payload) {
      state.adhocGroupDescending = payload
    },
    adhocRowsPerPage(state, payload) {
      state.adhocRowsPerPage = payload
    },
    adhocText(state, payload) {
      state.adhocText = payload
    },
    adhocPivotIncludePivot(state, payload) {
      state.adhocPivotIncludePivot = payload
    },
    adhocPivotFirst(state, payload) {
      state.adhocPivotFirst = payload
    },
    adhocPivotName(state, payload) {
      state.adhocPivotName = payload
    },
    adhocPivotRowFields(state, payload) {
      state.adhocPivotRowFields = payload
    },
    adhocPivotColumnFields(state, payload) {
      state.adhocPivotColumnFields = payload
    },
    adhocPivotExcludeFields(state, payload) {
      state.adhocPivotExcludeFields = payload
    },
    adhocPivotDataFields(state, payload) {
      state.adhocPivotDataFields = payload
    },
    adhocPivotFilterFields(state, payload) {
      state.adhocPivotFilterFields = payload
    },
    adhocSelectedFields(state, payload) {
      state.adhocSelectedFields = payload
    },
    adhocQueryIsModified(state, payload) {
      state.adhocQueryIsModified = payload
    },
    adhocQueryName(state, payload) {
      state.adhocQueryName = payload
    },
    adhocSearchCount(state, payload) {
      state.adhocSearchCount = payload
    },
    increaseAdhocCount(state, payload) {
      state.adhocSearchCount = state.adhocSearchCount + 1
    },
    decreaseAdhocCount(state, payload) {
      state.adhocSearchCount = state.adhocSearchCount - 1
    },
    //TemplateTODO: uncomment as needed
    adhocStartDate(state, payload) {
      state.adhocStartDate = payload
    },
    adhocEndDate(state, payload) {
      state.adhocEndDate = payload
    },
    //adhocApplicationId(state, payload) {
    //  state.adhocApplicationId = payload
    //},
    //adhocApplicationNumber(state, payload) {
    //  state.adhocApplicationNumber = payload
    //},
    adhocStatusId(state, payload) {
      state.adhocStatusId = payload
    },
    //adhocStatusCode(state, payload) {
    //  state.adhocStatusCode = payload
    //},
    adhocReasonId(state, payload) {
      state.adhocReasonId = payload
    },
    //adhocReasonDescription(state, payload) {
    //  state.adhocReasonDescription = payload
    //},
    //adhocReasonNumber(state, payload) {
    //  state.adhocReasonNumber = payload
    //},
    //adhocReasonType(state, payload) {
    //  state.adhocReasonType = payload
    //},
    adhocCountyId(state, payload) {
      state.adhocCountyId = payload
    },
    //adhocCityId(state, payload) {
    //  state.adhocCityId = payload
    //},
    //adhocCounty(state, payload) {
    //  state.adhocCounty = payload
    //},
    adhocPeriodId(state, payload) {
      state.adhocPeriodId = payload
    },
    //adhocFirstName(state, payload) {
    //  state.adhocFirstName = payload
    //},
    //adhocMiddleInitial(state, payload) {
    //  state.adhocMiddleInitial = payload
    //},
    //adhocLastName(state, payload) {
    //  state.adhocLastName = payload
    //},
    //adhocMailingAddress1(state, payload) {
    //  state.adhocMailingAddress1 = payload
    //},
    //adhocMailingAddress2(state, payload) {
    //  state.adhocMailingAddress2 = payload
    //},
    //adhocMailingCity(state, payload) {
    //  state.adhocMailingCity = payload
    //},
    //adhocMailingState(state, payload) {
    //  state.adhocMailingState = payload
    //},
    //adhocMailingZip(state, payload) {
    //  state.adhocMailingZip = payload
    //},
    //adhocParcelId(state, payload) {
    //  state.adhocParcelId = payload
    //},
    //adhocStreetAddress1(state, payload) {
    //  state.adhocStreetAddress1 = payload
    //},
    //adhocStreetAddress2(state, payload) {
    //  state.adhocStreetAddress2 = payload
    //},
    //adhocPropertyCity(state, payload) {
    //  state.adhocPropertyCity = payload
    //},
    //adhocPropertyState(state, payload) {
    //  state.adhocPropertyState = payload
    //},
    //adhocPropertyZip(state, payload) {
    //  state.adhocPropertyZip = payload
    //},
  },

  actions: {
    adhocBounce: _.debounce((context) => {
      context.dispatch('displayAdhocVwApplicationReports')
    }, 750),
    setAdhocPage(context, payload) {
      if (payload != context.state.adhocPage) {
        context.commit('adhocPage', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocSortBy)
      ) {
        context.commit('adhocSortBy', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocDescending(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocDescending)
      ) {
        context.commit('adhocDescending', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocGroupBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.adhocGroupBy)
      ) {
        context.commit('adhocGroupBy', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocGroupDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.adhocGroupDescending)
      ) {
        context.commit('adhocGroupDescending', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocRowsPerPage(context, payload) {
      if (payload != context.state.adhocRowsPerPage) {
        context.commit('adhocRowsPerPage', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocText(context, payload) {
      if (payload != context.state.adhocText) {
        context.commit('adhocText', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocText(context, payload) {
      if (payload != context.state.adhocText) {
        context.commit('adhocText', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocPivotIncludePivot(context, payload) {
      if (payload != context.state.adhocPivotIncludePivot) {
        context.commit('adhocPivotIncludePivot', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotFirst(context, payload) {
      if (payload != context.state.adhocPivotFirst) {
        context.commit('adhocPivotFirst', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotName(context, payload) {
      if (payload != context.state.adhocPivotName) {
        context.commit('adhocPivotName', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotRowFields(context, payload) {
      if (payload != context.state.adhocPivotRowFields) {
        context.commit('adhocPivotRowFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotColumnFields(context, payload) {
      if (payload != context.state.adhocPivotColumnFields) {
        context.commit('adhocPivotColumnFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotExcludeFields(context, payload) {
      if (payload != context.state.adhocPivotExcludeFields) {
        context.commit('adhocPivotExcludeFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotDataFields(context, payload) {
      if (payload != context.state.adhocPivotDataFields) {
        context.commit('adhocPivotDataFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocPivotFilterFields(context, payload) {
      if (payload != context.state.adhocPivotFilterFields) {
        context.commit('adhocPivotFilterFields', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocSelectedFields(context, payload) {
      if (payload != context.state.adhocSelectedFields) {
        context.commit('adhocSelectedFields', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    setAdhocQueryIsModified(context, payload) {
      if (payload != context.state.adhocQueryIsModified) {
        context.commit('adhocQueryIsModified', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocQueryName(context, payload) {
      if (payload != context.state.adhocQueryName) {
        context.commit('adhocQueryName', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocStartDate(context, payload) {
      if (payload != context.state.adhocStartDate) {
        context.commit('adhocStartDate', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    setAdhocEndDate(context, payload) {
      if (payload != context.state.adhocEndDate) {
        context.commit('adhocEndDate', payload)
        context.dispatch('adhocBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    //setAdhocApplicationId(context, payload) {
    //  if (payload != context.state.adhocApplicationId) {
    //    context.commit('adhocApplicationId', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocApplicationNumber(context, payload) {
    //  if (payload != context.state.adhocApplicationNumber) {
    //    context.commit('adhocApplicationNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocStatusId(context, payload) {
      if (payload != context.state.adhocStatusId) {
        context.commit('adhocStatusId', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocStatusCode(context, payload) {
    //  if (payload != context.state.adhocStatusCode) {
    //    context.commit('adhocStatusCode', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocReasonId(context, payload) {
      if (payload != context.state.adhocReasonId) {
        context.commit('adhocReasonId', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocReasonDescription(context, payload) {
    //  if (payload != context.state.adhocReasonDescription) {
    //    context.commit('adhocReasonDescription', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocReasonNumber(context, payload) {
    //  if (payload != context.state.adhocReasonNumber) {
    //    context.commit('adhocReasonNumber', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocReasonType(context, payload) {
    //  if (payload != context.state.adhocReasonType) {
    //    context.commit('adhocReasonType', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocCountyId(context, payload) {
      if (payload != context.state.adhocCountyId) {
        context.commit('adhocCountyId', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocCityId(context, payload) {
    //  if (payload != context.state.adhocCityId) {
    //    context.commit('adhocCityId', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocCounty(context, payload) {
    //  if (payload != context.state.adhocCounty) {
    //    context.commit('adhocCounty', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    setAdhocPeriodId(context, payload) {
      if (payload != context.state.adhocPeriodId) {
        context.commit('adhocPeriodId', payload)
        context.dispatch('adhocBounce', context)
        context.commit('adhocQueryIsModified', true)
      }
    },
    //setAdhocFirstName(context, payload) {
    //  if (payload != context.state.adhocFirstName) {
    //    context.commit('adhocFirstName', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocMiddleInitial(context, payload) {
    //  if (payload != context.state.adhocMiddleInitial) {
    //    context.commit('adhocMiddleInitial', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocLastName(context, payload) {
    //  if (payload != context.state.adhocLastName) {
    //    context.commit('adhocLastName', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocMailingAddress1(context, payload) {
    //  if (payload != context.state.adhocMailingAddress1) {
    //    context.commit('adhocMailingAddress1', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocMailingAddress2(context, payload) {
    //  if (payload != context.state.adhocMailingAddress2) {
    //    context.commit('adhocMailingAddress2', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocMailingCity(context, payload) {
    //  if (payload != context.state.adhocMailingCity) {
    //    context.commit('adhocMailingCity', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocMailingState(context, payload) {
    //  if (payload != context.state.adhocMailingState) {
    //    context.commit('adhocMailingState', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocMailingZip(context, payload) {
    //  if (payload != context.state.adhocMailingZip) {
    //    context.commit('adhocMailingZip', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocParcelId(context, payload) {
    //  if (payload != context.state.adhocParcelId) {
    //    context.commit('adhocParcelId', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocStreetAddress1(context, payload) {
    //  if (payload != context.state.adhocStreetAddress1) {
    //    context.commit('adhocStreetAddress1', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocStreetAddress2(context, payload) {
    //  if (payload != context.state.adhocStreetAddress2) {
    //    context.commit('adhocStreetAddress2', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocPropertyCity(context, payload) {
    //  if (payload != context.state.adhocPropertyCity) {
    //    context.commit('adhocPropertyCity', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocPropertyState(context, payload) {
    //  if (payload != context.state.adhocPropertyState) {
    //    context.commit('adhocPropertyState', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    //setAdhocPropertyZip(context, payload) {
    //  if (payload != context.state.adhocPropertyZip) {
    //    context.commit('adhocPropertyZip', payload)
    //    context.dispatch('adhocBounce', context)
    //    context.commit('adhocQueryIsModified', true)
    //  }
    //},
    async displayAdhocVwApplicationReports(context, payload) {
      if (!context.state.adhocSortBy) {
        context.commit('adhocSortBy', [adhocSortByDefault])
        context.commit('adhocDescending', [true])
      }
      if (!Array.isArray(context.state.adhocSortBy)) {
        if (context.state.adhocSortBy) {
          context.commit('adhocSortBy', [context.state.adhocSortBy])
          context.commit('adhocDescending', [context.state.adhocDescending])
        } else {
          context.commit('adhocSortBy', [adhocSortByDefault])
          context.commit('adhocDescending', [true])
        }
      }
      if (!context.state.adhocText) {
        context.commit('adhocText', '')
      }
      context.commit('increaseAdhocCount')
      var params = context.getters.adhocParameters
      var paramString = JSON.stringify(params)
      const url = `AdHocVwApplicationReportReport/Display`
      try {
        let res = await Vue.prototype.$axios.post(url, params)
        if (JSON.stringify(context.getters.adhocParameters) == paramString) {
          context.commit('adhocVwApplicationReports', res.data.Entries)
          context.commit('adhocRowsPerPage', res.data.Page.Size)
          context.commit('adhocPage', res.data.Page.Number + 1)
          context.commit('adhocTotal', res.data.Page.TotalElements)
        }
        context.commit('decreaseAdhocCount')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        context.commit('decreaseAdhocCount')
        throw err
      }
    },
    async downloadAdhoc(context, payload) {
      if (!context.state.adhocSortBy) {
        context.commit('adhocSortBy', [adhocSortByDefault])
        context.commit('adhocDescending', [true])
      }
      if (!Array.isArray(context.state.adhocSortBy)) {
        if (context.state.adhocSortBy) {
          context.commit('adhocSortBy', [context.state.adhocSortBy])
          context.commit('adhocDescending', [context.state.adhocDescending])
        } else {
          context.commit('adhocSortBy', [adhocSortByDefault])
          context.commit('adhocDescending', [true])
        }
      }
      let headers = { responseType: 'blob' }
      let url = 'AdHocVwApplicationReportReport'
      try {
        let res = await Vue.prototype.$axios.post(
          url,
          context.getters.adhocParameters,
          headers
        )
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        download(res.data, filename, content)
      } catch (err) {
        context.dispatch('errors/handleError', err, { root: true })
        console.error(err)
      }
    },
  },
}

export default vwApplicationReport
