import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const application = {
  state: {
    applications: [],
    selectedApplication: {},
    selectedApplicationOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    applications(state) {
      return state.applications
    },
    selectedApplication(state) {
      return state.selectedApplication
    },
    selectedApplicationOriginal(state) {
      return state.selectedApplicationOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    applications(state, payload) {
      state.applications = payload
    },
    selectedApplication(state, payload) {
      state.selectedApplication = JSON.parse(JSON.stringify(payload))
    },
    selectedApplicationOriginal(state, payload) {
      state.selectedApplicationOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedApplication(context, payload) {
      context.commit('selectedApplication', payload)
    },
    async loadApplications(context, payload) {
      const url = 'Application'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('applications', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedApplication(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id > 0
      ) {
        context.dispatch(
          'loadSelectedApplication',
          context.state.selectedApplication.Id
        )
      }
    },
    async loadSelectedApplication(context, payload) {
      if (
        context.state.selectedApplication &&
        context.state.selectedApplication.Id != payload
      ) {
        context.commit('selectedApplication', {})
      }
      const url = 'Application/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedApplication', res.data)
        context.commit('selectedApplicationOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadApplications(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
        context.dispatch('loadApplications')
      })
    },
    async createApplication(context, payload) {
      const url = `Application`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'application created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadApplications')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchApplication(context, payload) {
      var item = context.state.selectedApplication
      console.devlog(payload)
      if (
        payload.Id &&
        payload.Id == context.state.selectedApplicationOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedApplicationOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        console.devlog(patchPayload.patchDoc)
        const url = `Application/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'application updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadApplications')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateApplication(context, payload) {
      const url = `Application/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'application updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadApplications')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveApplication(context, payload) {
      if (payload.Id > 0) {
        return context.dispatch('updateApplication', payload)
      } else {
        return context.dispatch('createApplication', payload)
      }
    },
    async deleteApplication(context, payload) {
      const url = `Application/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'application deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadApplications')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default application
