import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const contact = {
  state: {
    contacts: [],
    selectedContact: {},
    selectedContactOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    contacts(state) {
      return state.contacts
    },
    selectedContact(state) {
      return state.selectedContact
    },
    selectedContactOriginal(state) {
      return state.selectedContactOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    contacts(state, payload) {
      state.contacts = payload
    },
    selectedContact(state, payload) {
      state.selectedContact = JSON.parse(JSON.stringify(payload))
    },
    selectedContactOriginal(state, payload) {
      state.selectedContactOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedContact(context, payload) {
      context.commit('selectedContact', payload)
    },
    async loadContacts(context, payload) {
      const url = 'Contact'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('contacts', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedContact(context, payload) {
      if (context.state.selectedContact && context.state.selectedContact.Id > 0) {
        context.dispatch('loadSelectedContact', context.state.selectedContact.Id)

      }
    },
    async loadSelectedContact(context, payload) {
      if (context.state.selectedContact && context.state.selectedContact.Id != payload) {
        context.commit('selectedContact', {})
      }
      const url = 'Contact/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedContact', res.data)
        context.commit('selectedContactOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadContacts(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadContacts')
      }, )
    },
    async createContact(context, payload){
      const url = `Contact`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'contact created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadContacts')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchContact(context, payload){
      var item = context.state.selectedContact
      if (payload.Id && 
      payload.Id == context.state.selectedContactOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedContactOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `Contact/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'contact updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadContacts')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateContact(context, payload){
      const url = `Contact/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'contact updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadContacts')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveContact(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateContact', payload)
      } else {
        return context.dispatch('createContact', payload)
      }
    },
    async deleteContact(context, payload){
      const url = `Contact/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'contact deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadContacts')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default contact