import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const cityCounty = {
  state: {
    cityCounties: [],
    selectedCityCounty: {},
    selectedCityCountyOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    cityCounties(state) {
      return state.cityCounties
    },
    selectedCityCounty(state) {
      return state.selectedCityCounty
    },
    selectedCityCountyOriginal(state) {
      return state.selectedCityCountyOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    cityCounties(state, payload) {
      state.cityCounties = payload
    },
    selectedCityCounty(state, payload) {
      state.selectedCityCounty = JSON.parse(JSON.stringify(payload))
    },
    selectedCityCountyOriginal(state, payload) {
      state.selectedCityCountyOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadCityCounties(context, payload) {
      const url = 'CityCounty'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('cityCounties', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedCityCounty(context, payload) {
      if (context.state.selectedCityCounty && 
        context.state.selectedCityCounty.CountyId > 0 &&
              context.state.selectedCityCounty.CityId > 0 
      ) {
      let keys = {
      countyId:  context.state.selectedCityCounty.CountyId,
      cityId:  context.state.selectedCityCounty.CityId,
      }
        context.dispatch('loadSelectedCityCounty', keys)
      }
    },
    async loadSelectedCityCounty(context, payload) {
      if (
      payload &&
      payload.countyId > 0 &&
            payload.cityId > 0
      ) {
      if (context.state.selectedCityCounty && (
      context.state.selectedCityCounty.CountyId != payload.countyId || 
            context.state.selectedCityCounty.CityId != payload.cityId 
      )) {
        context.commit('selectedCityCounty', {})
        context.commit('selectedCityCountyOriginal', {})
      }
        const url = 'CityCounty/'
        payload.countyId+ '/' +
        payload.cityId
        context.commit('increaseSearchCount')
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedCityCounty', res.data)
          context.commit('selectedCityCountyOriginal', res.data)
          context.commit('decreaseSearchCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseSearchCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    clearLookupCacheAndReloadCityCounties(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadCityCounties')
      }, )
    },
    async createCityCounty(context, payload){
      const url = `CityCounty`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'city county created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadCityCounties')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchCityCounty(context, payload){ 
      var item = context.state.selectedCityCounty
      if (
      payload.CountyId && payload.CountyId == context.state.selectedCityCountyOriginal.CountyId && 
      payload.CityId && payload.CityId == context.state.selectedCityCountyOriginal.CityId
      )  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc ||!payload.CountyId || 
    !payload.CityId
    ) {
        let original = context.state.selectedCityCountyOriginal
        let current = item
        patchPayload.CountyId = item.CountyId
        patchPayload.CityId = item.CityId
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
      const url = 'CityCounty?'+
      'countyId='+   patchPayload.CountyId+ '&' +
      'cityId='+   patchPayload.CityId
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
            .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'city county updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadCityCounties')
          context.commit('saving', false)
          return res
        }catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateCityCounty(context, payload){
      const url = 'CityCounty?'+
      'countyId='+   payload.CountyId+ '&' +
      'cityId='+   payload.CityId
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
        .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'city county updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadCityCounties')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async deleteCityCounty(context, payload){
        const url = 'CityCounty?'+
        'countyId='+   payload.CountyId+ '&' +
        'cityId='+   payload.CityId
      try {
        let res = await Vue.prototype.$axios
        .delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'city county deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadCityCounties')
        return res
      }catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default cityCounty