import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  // see https://vuetifyjs.com/en/features/theme/#customizing
  // for theming info and
  // https://vuetifyjs.com/en/styles/colors/#material-colors for built in colors
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#0E7C81', //'#0C6A6F'
        secondary: '#7FC6C9',
        accent: '#daf9fb',
        background: '#FFF',
        formSectionBg: colors.grey.lighten4,
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',

        // other custom properties can be defined and used
        // vuetify will make any you specify available as well as calculate lighter/darker variants for them

        // these are used for the toastui grid theming (if used)
        // border: '#d4d4d4',
        // hover: '#e9e9e9',
        // modified: '#e9f1f8',
        // text: '#000',
      },
      dark: {
        primary: '#13b0b9',
        secondary: '#7FC6C9',
        accent: '#daf9fb',
        background: '#000',
        formSectionBg: colors.grey.darken3,
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',

        //these are used for the toastui grid theming (if used)
        // border: '#3c3c3c',
        // hover: '#2b2b2b',
        // modified: '#234a6c',
        // text: '#FFF',
      },
      // App.vue has code in the mounted hook
      //that will auto switch the theme based on user preference
      //there is also an icon in the title bar of the nav-bar component
    },
  },
})
