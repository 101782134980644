import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luPeriod = {
  state: {
    luPeriods: [],
    selectedLuPeriod: {},
    selectedLuPeriodOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luPeriods(state) {
      return state.luPeriods
    },
    selectedLuPeriod(state) {
      return state.selectedLuPeriod
    },
    selectedLuPeriodOriginal(state) {
      return state.selectedLuPeriodOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luPeriods(state, payload) {
      state.luPeriods = payload
    },
    selectedLuPeriod(state, payload) {
      state.selectedLuPeriod = JSON.parse(JSON.stringify(payload))
    },
    selectedLuPeriodOriginal(state, payload) {
      state.selectedLuPeriodOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuPeriod(context, payload) {
      context.commit('selectedLuPeriod', payload)
    },
    async loadLuPeriods(context, payload) {
      const url = 'LuPeriod'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luPeriods', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuPeriod(context, payload) {
      if (context.state.selectedLuPeriod && context.state.selectedLuPeriod.Id > 0) {
        context.dispatch('loadSelectedLuPeriod', context.state.selectedLuPeriod.Id)

      }
    },
    async loadSelectedLuPeriod(context, payload) {
      if (context.state.selectedLuPeriod && context.state.selectedLuPeriod.Id != payload) {
        context.commit('selectedLuPeriod', {})
      }
      const url = 'LuPeriod/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuPeriod', res.data)
        context.commit('selectedLuPeriodOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuPeriods(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuPeriods')
      }, )
    },
    async createLuPeriod(context, payload){
      const url = `LuPeriod`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'period created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuPeriods')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuPeriod(context, payload){
      var item = context.state.selectedLuPeriod
      if (payload.Id && 
      payload.Id == context.state.selectedLuPeriodOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuPeriodOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuPeriod/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'period updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuPeriods')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuPeriod(context, payload){
      const url = `LuPeriod/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'period updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuPeriods')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuPeriod(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuPeriod', payload)
      } else {
        return context.dispatch('createLuPeriod', payload)
      }
    },
    async deleteLuPeriod(context, payload){
      const url = `LuPeriod/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'period deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuPeriods')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luPeriod