import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const vwApplicationsAvailableForPayment = {
  state: {
    vwApplicationsAvailableForPayments: [],
    selectedVwApplicationsAvailableForPayment: {},
    selectedVwApplicationsAvailableForPaymentOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    vwApplicationsAvailableForPayments(state) {
      return state.vwApplicationsAvailableForPayments
    },
    selectedVwApplicationsAvailableForPayment(state) {
      return state.selectedVwApplicationsAvailableForPayment
    },
    selectedVwApplicationsAvailableForPaymentOriginal(state) {
      return state.selectedVwApplicationsAvailableForPaymentOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    vwApplicationsAvailableForPayments(state, payload) {
      state.vwApplicationsAvailableForPayments = payload
    },
    selectedVwApplicationsAvailableForPayment(state, payload) {
      state.selectedVwApplicationsAvailableForPayment = JSON.parse(JSON.stringify(payload))
    },
    selectedVwApplicationsAvailableForPaymentOriginal(state, payload) {
      state.selectedVwApplicationsAvailableForPaymentOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadVwApplicationsAvailableForPayments(context, payload) {
      const url = 'VwApplicationsAvailableForPayment'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('vwApplicationsAvailableForPayments', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadVwApplicationsAvailableForPayments(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadVwApplicationsAvailableForPayments')
    },
    async createVwApplicationsAvailableForPayment(context, payload) {
      const url = `VwApplicationsAvailableForPayment`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'applications available for payment created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadVwApplicationsAvailableForPayments')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default vwApplicationsAvailableForPayment
