import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'
import dayjs from 'dayjs'

const luPeriod = {
  state: { currentLuPeriod: {}, errorMessage: '' },
  getters: {
    currentLuPeriod(state) {
      return state.currentLuPeriod
    },
    errorMessage(state) {
      return state.errorMessage
    },
    withinPeriod(state) {
      let period = state.currentLuPeriod
      let withinPeriod =
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.PeriodEndDate) >= dayjs()
      return period && withinPeriod
    },
    beforePeriod(state) {
      let period = state.currentLuPeriod
      return period && dayjs(period.ApplicationOpenDate) > dayjs()
    },
    applicationDeadlineExpired(state, getters) {
      let period = state.currentLuPeriod
      return (
        period &&
        dayjs(period.ApplicationDeadline) < dayjs() &&
        !getters.afterPeriod
      )
    },
    applicationsAccepted(state) {
      let period = state.currentLuPeriod
      return (
        period &&
        dayjs(period.ApplicationOpenDate) <= dayjs() &&
        dayjs(period.ApplicationDeadline) >= dayjs()
      )
    },
    afterPeriod(state) {
      let period = state.currentLuPeriod
      return period && dayjs(period.PeriodEndDate) < dayjs()
    },
  },
  mutations: {
    currentLuPeriod(state, payload) {
      state.currentLuPeriod = JSON.parse(JSON.stringify(payload))
    },
    errorMessage(state, payload) {
      state.errorMessage = JSON.parse(JSON.stringify(payload))
    },
  },
  actions: {
    async init({ dispatch }) {
      await dispatch('loadCurrentLuPeriod')
    },
    async loadCurrentLuPeriod(context, payload) {
      const url = 'LuPeriodPublic/Current'
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('currentLuPeriod', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        if (err.response.status == 404) {
          context.commit(
            'errorMessage',
            'Applications for the prior year are not yet being accepted.'
          )
          context.dispatch(
            'errors/handleError',
            'An application period for the previous year was not found',
            { root: true }
          )
        } else {
          context.dispatch('errors/handleError', err, { root: true })
        }

        throw err
      }
    },
  },
}

export default luPeriod
