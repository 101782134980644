import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luIncomeThreshold = {
  state: {
    luIncomeThresholds: [],
    selectedLuIncomeThreshold: {},
    selectedLuIncomeThresholdOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luIncomeThresholds(state) {
      return state.luIncomeThresholds
    },
    selectedLuIncomeThreshold(state) {
      return state.selectedLuIncomeThreshold
    },
    selectedLuIncomeThresholdOriginal(state) {
      return state.selectedLuIncomeThresholdOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luIncomeThresholds(state, payload) {
      state.luIncomeThresholds = payload
    },
    selectedLuIncomeThreshold(state, payload) {
      state.selectedLuIncomeThreshold = JSON.parse(JSON.stringify(payload))
    },
    selectedLuIncomeThresholdOriginal(state, payload) {
      state.selectedLuIncomeThresholdOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuIncomeThreshold(context, payload) {
      context.commit('selectedLuIncomeThreshold', payload)
    },
    async loadLuIncomeThresholds(context, payload) {
      const url = 'LuIncomeThreshold'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luIncomeThresholds', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedLuIncomeThreshold(context, payload) {
      if (
        context.state.selectedLuIncomeThreshold &&
        context.state.selectedLuIncomeThreshold.Id > 0
      ) {
        return await context.dispatch(
          'loadSelectedLuIncomeThreshold',
          context.state.selectedLuIncomeThreshold.Id
        )
      }
    },
    async loadSelectedLuIncomeThreshold(context, payload) {
      if (
        context.state.selectedLuIncomeThreshold &&
        context.state.selectedLuIncomeThreshold.Id != payload
      ) {
        context.commit('selectedLuIncomeThreshold', {})
      }
      const url = 'LuIncomeThreshold/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuIncomeThreshold', res.data)
        context.commit('selectedLuIncomeThresholdOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadLuIncomeThresholds(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadLuIncomeThresholds')
    },
    async createLuIncomeThreshold(context, payload) {
      const url = `LuIncomeThreshold`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'income threshold created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuIncomeThresholds')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuIncomeThreshold(context, payload) {
      var item = context.state.selectedLuIncomeThreshold
      if (
        payload.Id &&
        payload.Id == context.state.selectedLuIncomeThresholdOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuIncomeThresholdOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuIncomeThreshold/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'income threshold updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuIncomeThresholds')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuIncomeThreshold(context, payload) {
      const url = `LuIncomeThreshold/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'income threshold updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuIncomeThresholds')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuIncomeThreshold(context, payload) {
      if (payload.Id > 0) {
        return context.dispatch('updateLuIncomeThreshold', payload)
      } else {
        return context.dispatch('createLuIncomeThreshold', payload)
      }
    },
    async deleteLuIncomeThreshold(context, payload) {
      const url = `LuIncomeThreshold/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'income threshold deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuIncomeThresholds')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luIncomeThreshold
