<template>
  <div>
    <v-dialog persistent v-model="dialog" scrollable width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          <span v-if="!hasHeaderSlot"> Confirm? </span>
          <span v-else>
            <slot name="header"></slot>
          </span>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12"
              ><span v-if="!hasDefaultSlot">
                Once submited, you will NOT be able to update or make any changes
                to this application! Are you sure you wish to submit now?
              </span>
              <span v-else>
                <slot />
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = !dialog">Cancel</v-btn>
          <v-btn @click="callSubmit" outlined color="primary">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    submit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: {},
      dialog: false,
    }
  },
  created() {},
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasHeaderSlot() {
      return !!this.$slots['header']
    },
  },
  methods: {
    submitConfirm(item) {
      this.selected = item
      this.dialog = true
    },
    async callSubmit() {
      try {
        await this.submit(this.selected)
        this.$emit('submitApplication')
        this.dialog = false
      } catch (err) {
        this.$emit('error', err)
      }
    },
  },
}
</script>
<style scoped></style>
