import Vue from 'vue'
import download from 'downloadjs'

const document = {
  actions: {
    async downloadDocument(context, payload) {
      const url = 'Document/Download/' + payload
      let headers = { responseType: 'blob' }
      try {
        let res = await Vue.prototype.$axios.get(url, headers)
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        download(res.data, filename, content)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async printDocument(context, payload) {
      const url = 'Document/Download/' + payload
      let headers = { responseType: 'blob' }
      try {
        let res = await Vue.prototype.$axios.get(url, headers)
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        var file = new File([res.data], filename, { type: content })
        var fileURL = URL.createObjectURL(file)
        var docWindow = window.open(fileURL, '_blank')
        docWindow.print()
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async markSelectedAsPrinted(context, payload) {
      return new Promise((resolve, reject) => {
        const url = 'Document/MarkAsPrinted'

        Vue.prototype.$axios.post(url, payload.ids).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'Letters Marked As Printed',
              },
              { root: true }
            )
            context.commit('wolfsRunGenerating', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            reject(err)
          }
        )
      })
    },
    async downloadSelected(context, payload) {
      const url = 'Document/GetToPrint'
      let headers = { responseType: 'blob' }
      try {
        let res = await Vue.prototype.$axios.post(url, payload.ids, headers)
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        download(res.data, filename, content)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default document
