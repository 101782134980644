import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const application = {
  state: {
    priorApplication: {},
  },

  getters: {
    priorApplication(state) {
      return state.priorApplication
    },
  },

  mutations: {
    priorApplication(state, payload) {
      state.priorApplication = JSON.parse(JSON.stringify(payload))
    },
  },

  actions: {
    setPriorApplication(context, payload) {
      context.commit('priorApplication', payload)
    },
    async loadPriorApplication(context, payload) {
      if (
        context.state.priorApplication &&
        context.state.priorApplication.Id != payload
      ) {
        context.commit('priorApplication', {})
      }
      if (payload?.contactId && payload.periodId) {
        const url = `Application/PriorYear/${payload.contactId}/${payload.periodId}`
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('priorApplication', res.data)
          return res
        } catch (err) {
          console.error(err)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
  },
}

export default application
