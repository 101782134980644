// Globally register all filters for convenience, because they
// will be used very frequently. Filters are registered using the
// PascalCased version of their file name.

import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireFilter = require.context(
    // Look for files in the current directory
    '.',
    // Do not look in subdirectories
    false,
    // find .js files
    /\w\.js$/
)

// For each matching file name...
requireFilter.keys().forEach((fileName) => {
    if (fileName == './index.js') {
        return
    }
    // Get the filter config
    const filterConfig = requireFilter(fileName)
    // Get the PascalCase version of the filter name
    const filterName = upperFirst(
        camelCase(
            fileName
            // Remove the "./_" from the beginning
            .replace(/^\.\/_/, '')
            // Remove the file extension from the end
            .replace(/\.\w+$/, '')
        )
    )
    // Globally register the filter
    Vue.filter(filterName, filterConfig.default || filterConfig)
})