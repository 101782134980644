export default {
  BaseUrl: `/api/`,
  gapiConfig: {
    apiKey: 'AIzaSyAG3UFdQ0Uy-MXXj8r_vY8c6K8NCZZKWt4',
    clientId:
      '845319349417-1a3krufdmv73nk4o5mt5mvk41fb6jrdq.apps.googleusercontent.com',
    fetch_basic_profile: true,
    scope: 'email profile',
    // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
  },
  identityUrl: `https://identitysts-gcp.wyo.gov`,
}
