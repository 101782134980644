const configUrl =
  document.currentScript.src.substring(
    0,
    document.currentScript.src.lastIndexOf('/')
  ) + '/appinfo.json'

module.exports = async function() {
  const response = await fetch(configUrl)
  return await response.json()
}
