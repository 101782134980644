import Vue from 'vue'
import axios from 'axios'
import config from '@config/config'
import store from '@state/store'
import router from '@router'
import { throttleAdapterEnhancer } from 'axios-extensions'
import jwtDecode from 'jwt-decode'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let axiosConfig = {
  baseURL: config.BaseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, {
    threshold: 2 * 1000,
  }),
  // baseURL: 'http://localhost:5080/api'
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(axiosConfig)
function refreshTokenForUser(store, cb) {
  console.devlog('is refreshing', store.state.auth.isRefreshing)
  if (store.state.auth.isRefreshing) {
    const chained = store.state.auth.refreshingCall.then(cb)
    store.commit('auth/setRefreshingCall', chained)
    return chained
  }
  store.commit('auth/setRefreshingState', true)
  const refreshingCall = new Promise((resolve, reject) => {
    store
      .dispatch('auth/getBrowserUser', true)
      .then((res) => {
        if (store.state.auth.etsJwtToken) {
          var token = jwtDecode(store.state.auth.etsJwtToken)
          if (token.exp > Date.now() / 1000) {
            // the token is current
            store.commit('auth/setRefreshingState', false)
            store.commit('auth/setRefreshingCall', undefined)
            resolve(store.state.auth.etsJwtToken)
          } else {
            //if we have a old token we are going to try again
            let x = 0
            var intervalID = window.setInterval(() => {
              var token = jwtDecode(store.state.auth.etsJwtToken)
              if (token.exp > Date.now() / 1000) {
                // the token is current
                store.commit('auth/setRefreshingState', false)
                store.commit('auth/setRefreshingCall', undefined)
                window.clearInterval(intervalID)
                resolve(store.state.auth.etsJwtToken)
              } else {
                if (++x >= 12) {
                  window.clearInterval(intervalID)
                  reject()
                }
              }
            }, 500)
          }
        } else {
          // if we don't have a token we are going to try again in case it wasn't back
          let x = 0
          var intervalID = window.setInterval(() => {
            // if a promise has not been resolved we are going to return a reject
            var token = jwtDecode(store.state.auth.etsJwtToken)
            if (token.exp > Date.now() / 1000) {
              // the token is current
              store.commit('auth/setRefreshingState', false)
              store.commit('auth/setRefreshingCall', undefined)
              window.clearInterval(intervalID)
              resolve(store.state.auth.etsJwtToken)
            } else {
              if (++x >= 12) {
                window.clearInterval(intervalID)
                reject()
              }
            }
          }, 500)
        }
      })
      .then(cb)
      .catch((err) => {
        console.error(err)
        return Promise.reject(err)
      })
  })

  store.commit('auth/setRefreshingCall', refreshingCall)
  return refreshingCall
}

_axios.interceptors.request.use(
  function(axiosConfig) {
    // Do something before request is sent
    return axiosConfig
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    // console.info({ error })
    // console.info({ errorResponse: error.response })
    if (
      //our base controller has determined the user does not have a valid token
      error.response &&
      (!error.response.data ||
        (error.response.data.Data == 'Unauthorized' &&
          error.response.data.Success == false)) &&
      401 === error.response.status
    ) {
      //we will attempt to get the user and refresh the token
      return refreshTokenForUser(store)
        .then((_) => {
          var token = jwtDecode(store.state.auth.etsJwtToken)
          if (token.exp > Date.now() / 1000) {
            error.config.headers['Authorization'] =
              'Bearer ' + store.state.auth.etsJwtToken
            error.config.baseURL = undefined
            return _axios.request(error.config)
          }
        })
        .catch((err) => {
          if (router.history.current.name != 'login') {
            router.push({
              name: 'login',
              query: {
                redirectFrom: router.history.current.path,
              },
            })
          }
          return Promise.reject(error)
        }) //otherwise we need to redirect to login
    } else if (
      error.response.data instanceof Blob &&
      error.response.data.type === 'application/json'
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = (e) => {
          try {
            const errMsg = JSON.parse(e.target.result)
            error.response.data = errMsg
            reject(error)
          } catch (e) {
            reject(error)
          }
        }
        reader.onerror = (e) => {
          reject(error)
        }
        reader.readAsText(error.response.data)
      })
    } else {
      // Return error if not auth error
      return Promise.reject(error)
    }
  }
)

Plugin.install = function(Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
