import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luFundingSponsor = {
  state: {
    luFundingSponsors: [],
    selectedLuFundingSponsor: {},
    selectedLuFundingSponsorOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luFundingSponsors(state) {
      return state.luFundingSponsors
    },
    selectedLuFundingSponsor(state) {
      return state.selectedLuFundingSponsor
    },
    selectedLuFundingSponsorOriginal(state) {
      return state.selectedLuFundingSponsorOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luFundingSponsors(state, payload) {
      state.luFundingSponsors = payload
    },
    selectedLuFundingSponsor(state, payload) {
      state.selectedLuFundingSponsor = JSON.parse(JSON.stringify(payload))
    },
    selectedLuFundingSponsorOriginal(state, payload) {
      state.selectedLuFundingSponsorOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuFundingSponsor(context, payload) {
      context.commit('selectedLuFundingSponsor', payload)
    },
    async loadLuFundingSponsors(context, payload) {
      const url = 'LuFundingSponsor'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luFundingSponsors', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedLuFundingSponsor(context, payload) {
      if (
        context.state.selectedLuFundingSponsor &&
        context.state.selectedLuFundingSponsor.Id > 0
      ) {
        return await context.dispatch(
          'loadSelectedLuFundingSponsor',
          context.state.selectedLuFundingSponsor.Id
        )
      }
    },
    async loadSelectedLuFundingSponsor(context, payload) {
      if (
        context.state.selectedLuFundingSponsor &&
        context.state.selectedLuFundingSponsor.Id != payload
      ) {
        context.commit('selectedLuFundingSponsor', {})
      }
      const url = 'LuFundingSponsor/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuFundingSponsor', res.data)
        context.commit('selectedLuFundingSponsorOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadLuFundingSponsors(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadLuFundingSponsors')
    },
    async createLuFundingSponsor(context, payload) {
      const url = `LuFundingSponsor`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'funding sponsor created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuFundingSponsors')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuFundingSponsor(context, payload) {
      var item = context.state.selectedLuFundingSponsor
      if (
        payload.Id &&
        payload.Id == context.state.selectedLuFundingSponsorOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuFundingSponsorOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuFundingSponsor/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'funding sponsor updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuFundingSponsors')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuFundingSponsor(context, payload) {
      const url = `LuFundingSponsor/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'funding sponsor updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuFundingSponsors')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuFundingSponsor(context, payload) {
      if (payload.Id > 0) {
        return context.dispatch('updateLuFundingSponsor', payload)
      } else {
        return context.dispatch('createLuFundingSponsor', payload)
      }
    },
    async deleteLuFundingSponsor(context, payload) {
      const url = `LuFundingSponsor/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'funding sponsor deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuFundingSponsors')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luFundingSponsor
