import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const vwSummarySearch = {
  state: {
    vwSummarySearches: [],
    selectedVwSummarySearch: {},
    selectedVwSummarySearchOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    vwSummarySearches(state) {
      return state.vwSummarySearches
    },
    selectedVwSummarySearch(state) {
      return state.selectedVwSummarySearch
    },
    selectedVwSummarySearchOriginal(state) {
      return state.selectedVwSummarySearchOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    vwSummarySearches(state, payload) {
      state.vwSummarySearches = payload
    },
    selectedVwSummarySearch(state, payload) {
      state.selectedVwSummarySearch = JSON.parse(JSON.stringify(payload))
    },
    selectedVwSummarySearchOriginal(state, payload) {
      state.selectedVwSummarySearchOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadVwSummarySearches(context, payload) {
      const url = 'VwSummarySearch'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('vwSummarySearches', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadVwSummarySearches(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadVwSummarySearches')
      }, )
    },
    async createVwSummarySearch(context, payload){
      const url = `VwSummarySearch`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'summary search created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadVwSummarySearches')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default vwSummarySearch