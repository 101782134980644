<!-- NAV LAYOUT -->
<template>
  <div class="shellStyles">
    <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini">
      <v-list dense>
        <v-list-item class="px-2">
          <v-list-item-avatar tile>
            <v-icon title="Expand Toolbar" color="primary">domain</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="noHover">
            <v-list-item-title class="text-uppercase">PTRS</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn icon @click.stop="mini = !mini" small>
              <v-icon title="Condense Toolbar">chevron_left</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="(item, key) in routes" :key="key">
          <v-list-item
            v-if="item.route"
            @click.stop
            :to="item.name"
            :href="item.href"
            link
          >
            <v-list-item-icon>
              <v-tooltip v-if="mini" right>
                <template v-slot:activator="{ on }">
                  <v-badge
                    :value="!!item.badge"
                    bordered
                    overlap
                    :color="!!item.badgeColor ? item.badgeColor : item.color"
                    :content="item.badge"
                  >
                    <v-icon :color="item.color" :title="item.title">{{
                      item.icon
                    }}</v-icon>
                  </v-badge>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
              <span v-else>
                <v-badge
                  :value="!!item.badge"
                  bordered
                  :color="!!item.badgeColor ? item.badgeColor : item.color"
                  overlap
                  :content="item.badge"
                >
                  <v-icon :color="item.color" :title="item.title">{{
                    item.icon
                  }}</v-icon>
                </v-badge>
              </span>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <!-- List items with sub menu -->
          <v-list-group v-else no-action>
            <template v-slot:prependIcon>
              <v-badge
                :value="!!item.badge"
                bordered
                :color="!!item.badgeColor ? item.badgeColor : item.color"
                overlap
                :content="item.badge"
              >
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-badge>
            </template>
            <template v-slot:activator>
              <!-- <v-list-item-content> -->
              <v-list-item-title> {{ item.title }}</v-list-item-title>
              <!-- </v-list-item-content> -->
            </template>
            <v-list-item
              class="pl-8"
              v-for="sub in item.subroutes"
              :key="sub.title"
              :to="sub.name"
              :href="sub.href"
              link
            >
              <v-list-item-icon>
                <v-icon :color="sub.color" :title="sub.title">{{
                  sub.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pl-0">{{
                sub.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <!-- CLOSE HERE -->
    </v-navigation-drawer>

    <v-app-bar app dark class="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon v-if="!drawer" title="Show Toolbar">menu</v-icon>
        <v-icon v-if="drawer" title="Hide Toolbar">menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="headline text-h5 text-uppercase">
        <span>Property Tax&nbsp;</span>
        <span class="font-weight-light">
          Refund System
          <span v-if="version" class="caption"
            >&nbsp;Version {{ version }}</span
          >
        </span>
        <v-chip class="ml-3 mb-1" :color="envColor" v-if="environment">{{
          environment
        }}</v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <Signin></Signin>
      <BaseTooltipButton
        class="justify-end"
        iconColor="white"
        dark
        :icon="
          $vuetify.theme.dark
            ? 'mdi-white-balance-sunny'
            : 'mdi-moon-waxing-crescent'
        "
        @click="switchTheme"
        >{{
          $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode'
        }}</BaseTooltipButton
      >
      <!-- <feedback
        v-if="isDevelopment"
        class="justify-end"
        iconColor="white"
        dark
      ></feedback> -->
    </v-app-bar>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import Signin from '@components/login/sign-in'
import { authComputed } from '@state/helpers'
// import feedback from '@components/feedback'
export default {
  components: {
    // feedback,
    Signin,
  },
  data() {
    return {
      drawer: null,
      mini: true,
      version: VUE_APP_VERSION,

      persistentNavRoutes: [
        {
          name: '/',
          title: 'Home',
          icon: 'icon-homepage',
          color: 'primary',
          route: true,
        },
        {
          name: '/filingInstructions',
          title: 'Filing Instructions',
          icon: 'icon-book',
          color: 'primary',
          route: true,
        },
        // {
        //   name: '/about',
        //   title: 'About',
        //   icon: 'icon-information',
        //   color: 'primary',
        //   route: true,
        // },
      ],
      loggedInNavRoutes: [
        // {
        //   name: '/contactRegistration',
        //   title: 'Registration',
        //   title: 'Contact Form',
        //   icon: 'mdi-account',
        //   color: 'primary',
        //   route: true,
        // },
      ],
      loggedOutNavRoutes: [],
      roleSpecificNavRoutes: [
        {
          name: '/contactSearch',
          title: 'Applicant Search',
          icon: 'mdi-account-search',
          color: 'primary',
          roles: ['Admin', 'Staff'],
          route: true,
        },
        {
          name: '/inbox',
          title: 'Application Inbox',
          icon: 'mdi-inbox-full',
          color: 'primary',
          roles: ['Admin', 'Staff'],
          route: true,
        },
        {
          name: '/wolfs',
          title: 'Wolfs',
          icon: 'icon-wolf_bold',
          color: 'primary',
          roles: ['Admin'],
          route: true,
        },
        {
          name: '/letters',
          title: 'Letters',
          icon: 'mdi-email-multiple',
          color: 'primary',
          roles: ['Admin', 'Staff'],
          route: true,
        },
        {
          name: '/reports',
          title: 'Reports',
          icon: 'mdi-file-table',
          color: 'primary',
          roles: ['Admin', 'Staff'],
          route: true,
        },
        {
          name: '/admin',
          title: 'Admin',
          icon: 'icon-management',
          color: 'primary',
          roles: ['Admin'],
          route: true,
        },
        // {
        //   name: '/form',
        //   title: 'Refund Form',
        //   icon: 'icon-registration',
        //   color: 'primary',
        //   roles: ['Admin', 'Consumer'],
        //   route: true,
        // },
        {
          name: '/consumerForm',
          title: 'Refund Application',
          icon: 'icon-registration',
          color: 'primary',
          roles: ['Consumer'],
          route: true,
        },
        // {
        //   name: '/addProperty',
        //   title: 'Add Taxable Property',
        //   icon: 'icon-homepage',
        //   color: 'primary',
        //   roles: ['Consumer'],
        //   route: true,
        // },

        //*Subroute/Role example
        // {
        //   name: '',
        //   title: 'Subgroup Example',
        //   icon: 'mdi-ambulance',
        //   color: 'green',
        //   roles: ['Admin', 'User'],
        //   subroutes: [
        //     {
        //       name: '/subgroup/e1',
        //       title: 'Example1',
        //       icon: 'mdi-assistant',
        //       roles: ['Admin', 'User'],
        //     },
        //     {
        //       name: '/subgroup/e1/movies',
        //       title: 'Movies',
        //       icon: 'mdi-movie-roll',
        //       roles: ['Admin', 'User'],
        //     },
        //     {
        //       name: '/subgroup/e1/office',
        //       title: 'Office',
        //       icon: 'mdi-office-building',
        //       roles: ['Admin', 'User'],
        //     },
        //     {
        //       name: '/subgroup/e1/helpers',
        //       title: 'Helpers',
        //       icon: 'mdi-help-circle-outline',
        //       roles: ['User'],
        //     },
        //   ],
        // },
        // {
        //   name: 'about',
        //   title: 'example',
        //   icon: 'mdi-alert',
        //   color: 'orange',
        //   route: true,
        //   roles: ['Admin', 'User'],
        // },
      ],
      logOutRoutes: [
        {
          name: '/logout',
          title: 'Log Out',
          icon: 'lock_open',
          color: 'primary',
          route: true,
        },
      ],
      logInRoutes: [
        {
          name: '/login',
          title: 'Login',
          icon: 'lock',
          color: 'primary',
          route: true,
        },
      ],
    }
  },
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('darkMode', this.$vuetify.theme.dark)
    },
  },
  watch: {
    isLoggedIn(nval, oval) {
      if (nval) {
        if (this.$route.query != {} && this.$route.query.redirectFrom) {
          this.$router.push(this.$route.query.redirectFrom)
        } else if (this.$router.history.current.name == 'login') {
          // where you want the users to be routed to upon login
          if (this.currentUser.UserRoles.length == 0) {
            this.$router.push('contactRegistration')
          } else if (this.$route.name != null) {
            this.$router.push('/')
          }
        }
      } else {
        if (this.$router.history.current.name != 'login') {
          if (this.$router.history.current.path != '/') {
            this.$router.push({
              name: 'login',
              query: {
                redirectFrom: this.$router.history.current.path,
              },
            })
          } else {
            this.$router.push({
              name: 'login',
            })
          }
        }
      }
    },
    currentUser(nval, oval) {
      if (nval) {
      }
    },
  },
  computed: {
    ...authComputed,
    //this controls the display of the feedback component
    //unless otherwise specified we are only displaying it in development
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    environment() {
      let location = window.location.host
      if (location.includes('dev.') || location.includes('localhost')) {
        return 'Dev'
      } else if (location.includes('test.')) {
        return 'Test'
      } else if (location.includes('uat.')) {
        return 'UAT'
      } else if (location.includes('stage.')) {
        return 'Stage'
      } else {
        return null
      }
    },
    envColor() {
      switch (this.environment) {
        case 'Dev':
          return 'red darken-2'
        case 'Test':
          return 'orange darken-2'
        case 'UAT':
          return 'orange darken-2'
        case 'Stage':
          return 'yellow darken-2'
      }
    },
    routes() {
      var routes = this.persistentNavRoutes.filter((r) => true)
      if (this.isLoggedIn) {
        routes = routes.concat(this.loggedInNavRoutes)
        if (this.userRoles.length > 0) {
          this.roleSpecificNavRoutes.forEach((route) => {
            var routeMatch = route.roles.filter((r) =>
              this.userRoles.includes(r)
            )
            if (routeMatch.length > 0) {
              if (route.route) {
                routes.push(route)
              } else {
                let subroutes = []
                route.subroutes.forEach((sr) => {
                  var srMatch = sr.roles.filter((srr) =>
                    this.userRoles.includes(srr)
                  )
                  if (srMatch.length > 0) {
                    subroutes.push(sr)
                  }
                })
                route.subroutes = subroutes
                routes.push(route)
              }
            }
          })
        }
        routes = routes.concat(this.logOutRoutes)
      } else {
        routes = routes.concat(this.loggedOutNavRoutes)
        routes = routes.concat(this.logInRoutes)
      }
      return routes
    },
  },
}
</script>

<!-- STYLES -->
<style></style>
