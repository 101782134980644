<template>
  <v-text-field
    ref="field"
    :prefix="prefix"
    :value="formattedValue"
    @change="formattedValue = $event"
    :error-messages="errorMessages"
    v-bind="$attrs"
    :rules="validationRules"
  ></v-text-field>
</template>

<script>
function tryParseFloat(str, defaultValue) {
  var retValue = defaultValue
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(str)) {
        retValue = parseFloat(str)
      }
    }
  }
  return retValue
}
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '$ ',
    },
    thousandsSeparator: {
      type: String,
      default: ',',
    },
    decimalSeparator: {
      type: String,
      default: '.',
    },
    rules: {
      type: Array,
    },
    languageCode: {
      type: String,
      default: 'en-US',
    },
  },
  data() {
    return {
      isValid: true,
      thousandsSeparatorRegex: new RegExp(`\\${this.thousandsSeparator}`, 'g'),
      decimalSeparatorRegex: new RegExp(`\\${this.decimalSeparator}`, 'g'),
      errorMessages: [],
    }
  },
  computed: {
    formattedValue: {
      get() {
        if (this.value != null && this.value != undefined) {
          let v = this.value.toLocaleString(this.languageCode)
          if (
            v.length !== 1 &&
            v.slice(v.indexOf(this.decimalSeparator) + 1).length === 1
          )
            v += '0'
          return v
        } else {
          return ''
        }
      },
      set(newValue) {
        let v = newValue
        this.isValid = true
        this.errorMessages = []
        let parsed
        if (newValue == '' || newValue == null) {
          this.$emit('change', null)
          return
        }
        v = v.replace(this.thousandsSeparatorRegex, '')
        if (this.decimalSeparator !== '.')
          v = v.replace(this.decimalSeparatorRegex, '.')
        const result = tryParseFloat(v)
        if (result == undefined) {
          console.devlog('invalid number', result)
          this.isValid = false
          this.errorMessages.push('invalid value')
        } else {
          parsed = result
          this.isValid = true
          this.errorMessages = []
        }
        if (!this.allowNegative && result < 0) {
          this.isValid = false
          this.errorMessages.push('value must be positive')
        }
        if (this.isValid) {
          let numberValue = Math.round(parsed * 100) / 100
          this.$emit('change', numberValue)
        }
      },
    },
    validationRules() {
      let rules = this.rules || []
      return rules
    },
  },
}
</script>

<style lang="scss" scoped></style>
