import { VCombobox } from 'vuetify/lib'
// this fixes an issue where the tab order is reset to the beginning of the
//form after selecting an option with the mouse
export default {
  name: 'base-combobox',
  extends: VCombobox,
  methods: {
    blur() {
      setTimeout(this.handleBlur, 0)
    },
    handleBlur() {
      this.isMenuActive = false
      this.isFocused = false
      this.selectedIndex = -1
    },
    handleTabDown(e) {
      if (this.isMenuActive) {
        VCombobox.options.methods.onTabDown.call(this, e)
      } else {
        this.handleBlur()
      }
    },
  },
}
