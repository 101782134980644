import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const vwLettersToPrint = {
  state: {
    vwLettersToPrints: [],
    selectedVwLettersToPrint: {},
    selectedVwLettersToPrintOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    vwLettersToPrints(state) {
      return state.vwLettersToPrints
    },
    selectedVwLettersToPrint(state) {
      return state.selectedVwLettersToPrint
    },
    selectedVwLettersToPrintOriginal(state) {
      return state.selectedVwLettersToPrintOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    vwLettersToPrints(state, payload) {
      state.vwLettersToPrints = payload
    },
    selectedVwLettersToPrint(state, payload) {
      state.selectedVwLettersToPrint = JSON.parse(JSON.stringify(payload))
    },
    selectedVwLettersToPrintOriginal(state, payload) {
      state.selectedVwLettersToPrintOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadVwLettersToPrints(context, payload) {
      const url = 'VwLettersToPrint'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('vwLettersToPrints', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadVwLettersToPrints(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadVwLettersToPrints')
    },
    async createVwLettersToPrint(context, payload) {
      const url = `VwLettersToPrint`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'letters to print created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadVwLettersToPrints')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default vwLettersToPrint
