<template>
  <span v-if="isLoggedIn">
    <v-btn
      color="background"
      :dark="$vuetify.theme.dark ? true : false"
      :light="$vuetify.theme.dark ? false : true"
      @click="logoutAndRedirect"
    >
      <v-icon>icon-cowboy</v-icon>
      &nbsp;Sign out<span v-if="currentUser"
        >: {{ currentUser.FirstName }} {{ currentUser.LastName }}</span
      >
    </v-btn>
  </span>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {},
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
    logoutAndRedirect() {
      if (this.$router.history.current.name != 'home') {
        this.$router.push({ name: 'home' })
      }
      this.logOut()
    },
  },
  watch: {},
}
</script>
<style scoped></style>
