import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const statusTemplate = {
  state: {
    statusTemplates: [],
    selectedStatusTemplate: {},
    selectedStatusTemplateOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    statusTemplates(state) {
      return state.statusTemplates
    },
    selectedStatusTemplate(state) {
      return state.selectedStatusTemplate
    },
    selectedStatusTemplateOriginal(state) {
      return state.selectedStatusTemplateOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    statusTemplates(state, payload) {
      state.statusTemplates = payload
    },
    selectedStatusTemplate(state, payload) {
      state.selectedStatusTemplate = JSON.parse(JSON.stringify(payload))
    },
    selectedStatusTemplateOriginal(state, payload) {
      state.selectedStatusTemplateOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    async loadStatusTemplates(context, payload) {
      const url = 'StatusTemplate'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('statusTemplates', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedStatusTemplate(context, payload) {
      if (
        context.state.selectedStatusTemplate &&
        context.state.selectedStatusTemplate.TemplateId > 0 &&
        context.state.selectedStatusTemplate.StatusId > 0 
      ) {
      let keys = {
      templateId:  context.state.selectedStatusTemplate.TemplateId,
      statusId:  context.state.selectedStatusTemplate.StatusId,
      }
        return await context.dispatch('loadSelectedStatusTemplate', keys)
      }
    },
    async loadSelectedStatusTemplate(context, payload) {
      if (
      payload &&
      payload.templateId > 0 &&
            payload.statusId > 0
      ) {
      if (context.state.selectedStatusTemplate && (
      context.state.selectedStatusTemplate.TemplateId != payload.templateId || 
            context.state.selectedStatusTemplate.StatusId != payload.statusId 
      )) {
        context.commit('selectedStatusTemplate', {})
        context.commit('selectedStatusTemplateOriginal', {})
      }
        const url = 'StatusTemplate/'
        payload.templateId+ '/' +
        payload.statusId
        context.commit('increaseSearchCount')
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('selectedStatusTemplate', res.data)
          context.commit('selectedStatusTemplateOriginal', res.data)
          context.commit('decreaseSearchCount')
          return res
        } catch (err) {
          console.error(err)
          context.commit('decreaseSearchCount')
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      }
    },
    async clearLookupCacheAndReloadStatusTemplates(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadStatusTemplates')
    },
    async createStatusTemplate(context, payload) {
      const url = `StatusTemplate`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'status template created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadStatusTemplates')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchStatusTemplate(context, payload) { 
      var item = context.state.selectedStatusTemplate
      if (
        payload.TemplateId &&
        payload.TemplateId == context.state.selectedStatusTemplateOriginal.TemplateId && 
        payload.StatusId &&
        payload.StatusId == context.state.selectedStatusTemplateOriginal.StatusId
      ) {
         item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc ||!payload.TemplateId || 
    !payload.StatusId
    ) {
        let original = context.state.selectedStatusTemplateOriginal
        let current = item
        patchPayload.TemplateId = item.TemplateId
        patchPayload.StatusId = item.StatusId
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
      const url = 'StatusTemplate?'+
      'templateId='+   patchPayload.TemplateId+ '&' +
      'statusId='+   patchPayload.StatusId
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
            .patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'status template updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadStatusTemplates')
          context.commit('saving', false)
          return res
        }catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateStatusTemplate(context, payload) {
      const url = 'StatusTemplate?'+
      'templateId='+   payload.TemplateId+ '&' +
      'statusId='+   payload.StatusId
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
        .put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'status template updated', },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadStatusTemplates')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async deleteStatusTemplate(context, payload) {
        const url = 'StatusTemplate?'+
        'templateId='+   payload.TemplateId+ '&' +
        'statusId='+   payload.StatusId
      try {
        let res = await Vue.prototype.$axios
        .delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'status template deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadStatusTemplates')
        return res
      }catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default statusTemplate
