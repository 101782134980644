import Vue from 'vue'
import { debounce, range } from 'lodash'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const searchSortByDefault = 'Id'
const user = {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: [searchSortByDefault],
    searchDescending: [true],
    searchRowsPerPage: 25,
    searchText: '',
    lookupText: '',
    //templateTODO: uncomment as used in UserSearchParams
    searchShowInactive: false,
    searchRole: [1, 2],
    // searchIsActive: [],
  },

  getters: {
    searchParameters(state) {
      var sortDirection
      if (Array.isArray(state.searchDescending)) {
        sortDirection = state.searchDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.searchDescending ? ['desc'] : ['asc']
      }
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection,
        searchText: state.searchText,
        //templateTODO: uncomment as used in UserSearchParams
        showInactive: state.searchShowInactive,
        role: state.searchRole,
        //isActive: state.searchIsActive,
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    //TemplateTODO: uncomment as needed
    searchShowInactive(state, payload) {
      state.searchShowInactive = payload
    },
    searchRole(state, payload) {
      state.searchRole = payload
    },
    //searchIsActive(state, payload) {
    //  state.searchIsActive = payload
    //},
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupUsers')
    }, 300),
    searchBounce: _.debounce((context) => {
      context.dispatch('searchUsers')
    }, 750),
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.searchSortBy)
      ) {
        context.commit('searchSortBy', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.searchDescending)
      ) {
        context.commit('searchDescending', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText && payload != null) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    setSearchShowInactive(context, payload) {
      if (payload != context.state.searchShowInactive) {
        context.commit('searchShowInactive', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRole(context, payload) {
      if (payload != context.state.searchRole) {
        context.commit('searchRole', payload)
        context.dispatch('searchBounce', context)
      }
    },
    //setSearchIsActive(context, payload) {
    //  if (payload != context.state.searchIsActive) {
    //    context.commit('searchIsActive', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    async searchUsers(context, payload) {
      if (!context.state.searchSortBy) {
        context.commit('searchSortBy', [searchSortByDefault])
        context.commit('searchDescending', [true])
      }
      if (!Array.isArray(context.state.searchSortBy)) {
        if (context.state.searchSortBy) {
          context.commit('searchSortBy', [context.state.searchSortBy])
          context.commit('searchDescending', [context.state.searchDescending])
        } else {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
      }
      if (!context.state.searchText) {
        context.commit('searchText', '')
      }
      context.commit('increaseSearchCount')
      var params = context.getters.searchParameters
      var paramString = JSON.stringify(params)
      const url = `User/Search`
      try {
        let res = await Vue.prototype.$axios.post(url, params)
        if (JSON.stringify(context.getters.searchParameters) == paramString) {
          context.commit('users', res.data.Entries)
          context.commit('searchRowsPerPage', res.data.Page.Size)
          context.commit('searchPage', res.data.Page.Number + 1)
          context.commit('searchTotal', res.data.Page.TotalElements)
        }
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        context.commit('decreaseSearchCount')
        throw err
      }
    },
    async lookupUsers(context, payload) {
      if (context.state.lookupText) {
        context.commit('increaseSearchCount')
        const url = `User/Lookup?searchText=${context.state.lookupText}`
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('users', res.data)
          context.commit('decreaseSearchCount')
          return res
        } catch (err) {
          console.error(err)
          context.dispatch('errors/handleError', err, { root: true })
          context.commit('decreaseSearchCount')
          throw err
        }
      }
    },
  },
}

export default user
