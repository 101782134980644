import Vue from 'vue'
import './plugins/axios'
import './plugins/google'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from '@router'
import store from '@state/store'
import '@components/_globals'
import './plugins/logger'
import '@filters'
import './registerServiceWorker'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.$filters = Vue.options.filters

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
