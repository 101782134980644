import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const householdMember = {
  state: {
    householdMembers: [],
    selectedHouseholdMember: {},
    selectedHouseholdMemberOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    householdMembers(state) {
      return state.householdMembers
    },
    selectedHouseholdMember(state) {
      return state.selectedHouseholdMember
    },
    selectedHouseholdMemberOriginal(state) {
      return state.selectedHouseholdMemberOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    householdMembers(state, payload) {
      state.householdMembers = payload
    },
    selectedHouseholdMember(state, payload) {
      state.selectedHouseholdMember = JSON.parse(JSON.stringify(payload))
    },
    selectedHouseholdMemberOriginal(state, payload) {
      state.selectedHouseholdMemberOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedHouseholdMember(context, payload) {
      context.commit('selectedHouseholdMember', payload)
    },
    async loadHouseholdMembers(context, payload) {
      const url = 'HouseholdMember'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('householdMembers', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedHouseholdMember(context, payload) {
      if (context.state.selectedHouseholdMember && context.state.selectedHouseholdMember.Id > 0) {
        context.dispatch('loadSelectedHouseholdMember', context.state.selectedHouseholdMember.Id)

      }
    },
    async loadSelectedHouseholdMember(context, payload) {
      if (context.state.selectedHouseholdMember && context.state.selectedHouseholdMember.Id != payload) {
        context.commit('selectedHouseholdMember', {})
      }
      const url = 'HouseholdMember/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedHouseholdMember', res.data)
        context.commit('selectedHouseholdMemberOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadHouseholdMembers(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadHouseholdMembers')
      }, )
    },
    async createHouseholdMember(context, payload){
      const url = `HouseholdMember`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'household member created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadHouseholdMembers')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchHouseholdMember(context, payload){
      var item = context.state.selectedHouseholdMember
      if (payload.Id && 
      payload.Id == context.state.selectedHouseholdMemberOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedHouseholdMemberOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `HouseholdMember/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'household member updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadHouseholdMembers')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateHouseholdMember(context, payload){
      const url = `HouseholdMember/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'household member updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadHouseholdMembers')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveHouseholdMember(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateHouseholdMember', payload)
      } else {
        return context.dispatch('createHouseholdMember', payload)
      }
    },
    async deleteHouseholdMember(context, payload){
      const url = `HouseholdMember/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'household member deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadHouseholdMembers')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default householdMember