import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const wolfsItem = {
  state: {
    wolfsItems: [],
    selectedWolfsItem: {},
    selectedWolfsItemOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    wolfsItems(state) {
      return state.wolfsItems
    },
    selectedWolfsItem(state) {
      return state.selectedWolfsItem
    },
    selectedWolfsItemOriginal(state) {
      return state.selectedWolfsItemOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    wolfsItems(state, payload) {
      state.wolfsItems = payload
    },
    selectedWolfsItem(state, payload) {
      state.selectedWolfsItem = JSON.parse(JSON.stringify(payload))
    },
    selectedWolfsItemOriginal(state, payload) {
      state.selectedWolfsItemOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedWolfsItem(context, payload) {
      context.commit('selectedWolfsItem', payload)
    },
    async loadWolfsItems(context, payload) {
      const url = 'WolfsItem'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('wolfsItems', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedWolfsItem(context, payload) {
      if (
        context.state.selectedWolfsItem &&
        context.state.selectedWolfsItem.Id > 0
      ) {
        return await context.dispatch(
          'loadSelectedWolfsItem',
          context.state.selectedWolfsItem.Id
        )
      }
    },
    async loadSelectedWolfsItem(context, payload) {
      if (
        context.state.selectedWolfsItem &&
        context.state.selectedWolfsItem.Id != payload
      ) {
        context.commit('selectedWolfsItem', {})
      }
      const url = 'WolfsItem/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedWolfsItem', res.data)
        context.commit('selectedWolfsItemOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadWolfsItems(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadWolfsItems')
    },
    async createWolfsItem(context, payload) {
      const url = `WolfsItem`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'wolfs item created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadWolfsItems')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchWolfsItem(context, payload) {
      var item = context.state.selectedWolfsItem
      if (
        payload.Id &&
        payload.Id == context.state.selectedWolfsItemOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedWolfsItemOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `WolfsItem/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'wolfs item updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadWolfsItems')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateWolfsItem(context, payload) {
      const url = `WolfsItem/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'wolfs item updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadWolfsItems')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveWolfsItem(context, payload) {
      if (payload.Id > 0) {
        return context.dispatch('updateWolfsItem', payload)
      } else {
        return context.dispatch('createWolfsItem', payload)
      }
    },
    async deleteWolfsItem(context, payload) {
      const url = `WolfsItem/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'wolfs item deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadWolfsItems')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default wolfsItem
