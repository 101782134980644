import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luApplicationStatus = {
  state: {
    luApplicationStatuses: [],
    selectedLuApplicationStatus: {},
    selectedLuApplicationStatusOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luApplicationStatuses(state) {
      return state.luApplicationStatuses
    },
    selectedLuApplicationStatus(state) {
      return state.selectedLuApplicationStatus
    },
    selectedLuApplicationStatusOriginal(state) {
      return state.selectedLuApplicationStatusOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luApplicationStatuses(state, payload) {
      state.luApplicationStatuses = payload
    },
    selectedLuApplicationStatus(state, payload) {
      state.selectedLuApplicationStatus = JSON.parse(JSON.stringify(payload))
    },
    selectedLuApplicationStatusOriginal(state, payload) {
      state.selectedLuApplicationStatusOriginal = JSON.parse(
        JSON.stringify(payload)
      )
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuApplicationStatus(context, payload) {
      context.commit('selectedLuApplicationStatus', payload)
    },
    async loadLuApplicationStatuses(context, payload) {
      const url = 'LuApplicationStatus'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luApplicationStatuses', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async refreshSelectedLuApplicationStatus(context, payload) {
      if (
        context.state.selectedLuApplicationStatus &&
        context.state.selectedLuApplicationStatus.Id > 0
      ) {
        return await context.dispatch(
          'loadSelectedLuApplicationStatus',
          context.state.selectedLuApplicationStatus.Id
        )
      }
    },
    async loadSelectedLuApplicationStatus(context, payload) {
      if (
        context.state.selectedLuApplicationStatus &&
        context.state.selectedLuApplicationStatus.Id != payload
      ) {
        context.commit('selectedLuApplicationStatus', {})
      }
      const url = 'LuApplicationStatus/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuApplicationStatus', res.data)
        context.commit('selectedLuApplicationStatusOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async clearLookupCacheAndReloadLuApplicationStatuses(context, payload) {
      await caches.delete('apilookup-cache')
      return await context.dispatch('loadLuApplicationStatuses')
    },
    async createLuApplicationStatus(context, payload) {
      const url = `LuApplicationStatus`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'application status created' },
          { root: true }
        )
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuApplicationStatuses')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuApplicationStatus(context, payload) {
      var item = context.state.selectedLuApplicationStatus
      if (
        payload.Id &&
        payload.Id == context.state.selectedLuApplicationStatusOriginal.Id
      ) {
        item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuApplicationStatusOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(original, current)
        patchPayload.patchDoc = diff
      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuApplicationStatus/${patchPayload.Id}`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios.patch(url, patchPayload.patchDoc)
          context.dispatch(
            'messages/toastMessage',
            { color: 'success', message: 'application status updated' },
            { root: true }
          )
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuApplicationStatuses')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuApplicationStatus(context, payload) {
      const url = `LuApplicationStatus/${payload.Id}`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.put(url, payload)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'application status updated' },
          { root: true }
        )
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuApplicationStatuses')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuApplicationStatus(context, payload) {
      if (payload.Id > 0) {
        return context.dispatch('updateLuApplicationStatus', payload)
      } else {
        return context.dispatch('createLuApplicationStatus', payload)
      }
    },
    async deleteLuApplicationStatus(context, payload) {
      const url = `LuApplicationStatus/${payload.Id}`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch(
          'messages/toastMessage',
          { color: 'success', message: 'application status deleted' },
          { root: true }
        )
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuApplicationStatuses')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luApplicationStatus
