import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const countyMedian = {
  state: {
    countyMedians: [],
    selectedCountyMedian: {},
    selectedCountyMedianOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    countyMedians(state) {
      return state.countyMedians
    },
    selectedCountyMedian(state) {
      return state.selectedCountyMedian
    },
    selectedCountyMedianOriginal(state) {
      return state.selectedCountyMedianOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    countyMedians(state, payload) {
      state.countyMedians = payload
    },
    selectedCountyMedian(state, payload) {
      state.selectedCountyMedian = JSON.parse(JSON.stringify(payload))
    },
    selectedCountyMedianOriginal(state, payload) {
      state.selectedCountyMedianOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedCountyMedian(context, payload) {
      context.commit('selectedCountyMedian', payload)
    },
    async loadCountyMedians(context, payload) {
      const url = 'CountyMedian'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('countyMedians', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedCountyMedian(context, payload) {
      if (context.state.selectedCountyMedian && context.state.selectedCountyMedian.Id > 0) {
        context.dispatch('loadSelectedCountyMedian', context.state.selectedCountyMedian.Id)

      }
    },
    async loadSelectedCountyMedian(context, payload) {
      if (context.state.selectedCountyMedian && context.state.selectedCountyMedian.Id != payload) {
        context.commit('selectedCountyMedian', {})
      }
      const url = 'CountyMedian/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedCountyMedian', res.data)
        context.commit('selectedCountyMedianOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadCountyMedians(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadCountyMedians')
      }, )
    },
    async createCountyMedian(context, payload){
      const url = `CountyMedian`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'county median created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadCountyMedians')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchCountyMedian(context, payload){
      var item = context.state.selectedCountyMedian
      if (payload.Id && 
      payload.Id == context.state.selectedCountyMedianOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedCountyMedianOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `CountyMedian/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'county median updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadCountyMedians')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateCountyMedian(context, payload){
      const url = `CountyMedian/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'county median updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadCountyMedians')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveCountyMedian(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateCountyMedian', payload)
      } else {
        return context.dispatch('createCountyMedian', payload)
      }
    },
    async deleteCountyMedian(context, payload){
      const url = `CountyMedian/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'county median deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadCountyMedians')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default countyMedian