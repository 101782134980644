<!-- BODY LAYOUT -->
<template>
  <div id="app">
    <v-app>
      <Layout>
        <v-main>
          <!--
        $route.fullPath - will always load components as distinct components
        this will allow you to navigate from one instance of a component to another
        (from one item to another) and it will recreate the component so that the data is changed
          -->
          <RouterView :key="$route.fullPath"> </RouterView>
        </v-main>
      </Layout>
      <v-snackbar
        bottom
        right
        v-model="updateExists"
        :timeout="-1"
        color="primary"
      >
        An update is available
        <template v-slot:action="{ attrs }">
          <v-btn text @click="refreshApp" v-bind="attrs"> Update </v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import appConfig from '@src/app.config'
import { authMethods, authComputed } from '@state/helpers'
import mainVue from './router/layouts/main.vue'

export default {
  name: 'App',
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  data() {
    return {
      registration: null,
      updateExists: false,
    }
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
  },
  mounted() {
    this.setUpTokenRefresh()
    this.refreshToken()
    let localDarkModeSetting = localStorage.getItem('darkMode')
    if (localDarkModeSetting != null) {
      setTimeout(
        () =>
          (this.$vuetify.theme.dark =
            localDarkModeSetting === 'true' ? true : false),
        0
      )
    } else {
      const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      darkMediaQuery.addEventListener('change', (e) => {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      })
      if (darkMediaQuery.matches) {
        // console.info('change default light to dark theme')
        // need to set 0 sec timeout to set the dark more after mounted event, due to some bug in the framework
        setTimeout(() => (this.$vuetify.theme.dark = true), 0)
      }
    }
  },
  computed: { ...authComputed },
  methods: {
    ...authMethods,
    setUpTokenRefresh() {
      try {
        //this refreshes the etsjwt token
        window.setInterval(() => {
          this.refreshToken()
        }, 1000 * 60 * 20)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },
  components: { Layout: mainVue },
}
</script>

<!-- STYLES -->
<style>
.pointer:hover {
  cursor: pointer;
}
</style>
