import Vue from 'vue'
import download from 'downloadjs'

const wolfsRun = {
  state: {
    wolfsRunGenerating: false,
    wolfsRunDisbursing: false,
    downloadPending: false,
  },

  getters: {},

  mutations: {
    wolfsRunGenerating(state, payload) {
      state.wolfsRunGenerating = payload
    },
    wolfsRunDisbursing(state, payload) {
      state.wolfsRunDisbursing = payload
    },
    downloadPending(state, payload) {
      state.downloadPending = payload
    },
  },

  actions: {
    async generateRunFromSelected(context, payload) {
      return new Promise((resolve, reject) => {
        console.devlog(payload)
        let { ids, fundingSourceId } = payload
        let url = `WolfsRun/GenerateSelected/`
        url += fundingSourceId

        context.commit('wolfsRunGenerating', true)
        Vue.prototype.$axios.post(url, ids).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'Wolfs Run Generated',
              },
              { root: true }
            )
            context.commit('wolfsRunGenerating', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            context.commit('wolfsRunGenerating', false)
            reject(err)
          }
        )
      })
    },
    async markRunAsDisbursed(context, payload) {
      return new Promise((resolve, reject) => {
        let url = `WolfsRun/Disburse/${payload}`

        context.commit('wolfsRunDisbursing', true)
        Vue.prototype.$axios.get(url).then(
          (res) => {
            context.dispatch(
              'messages/toastMessage',
              {
                color: 'success',
                message: 'Wolfs Run Disbursed',
              },
              { root: true }
            )
            context.commit('wolfsRunDisbursing', false)
            resolve(res)
          },
          (err) => {
            console.error(err)
            context.dispatch('errors/handleError', err, { root: true })
            context.commit('wolfsRunDisbursing', false)
            reject(err)
          }
        )
      })
    },
    async downloadWolfsFile(context, payload) {
      const url = 'WolfsRun/GetWolfsFile/' + payload
      let headers = { responseType: 'blob' }
      try {
        let res = await Vue.prototype.$axios.get(url, headers)
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        download(res.data, filename, content)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async downloadWolfsTextFile(context, payload) {
      const url = 'WolfsRun/GetWolfsTextFile/' + payload
      let headers = { responseType: 'blob' }
      try {
        let res = await Vue.prototype.$axios.get(url, headers)
        const content = res.headers['content-type']
        const dispo = res.headers['content-disposition']
        var filename = ''
        if (dispo && dispo.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(dispo)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        download(res.data, filename, content)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default wolfsRun
