import Vue from 'vue'
import * as jsonpatch from 'fast-json-patch/index.mjs'

const luRole = {
  state: {
    luRoles: [],
    selectedLuRole: {},
    selectedLuRoleOriginal: {},
    saving: false,
    searchCount: 0,
  },

  getters: {
    luRoles(state) {
      return state.luRoles
    },
    selectedLuRole(state) {
      return state.selectedLuRole
    },
    selectedLuRoleOriginal(state) {
      return state.selectedLuRoleOriginal
    },
    loading(state) {
      return state.searchCount > 0
    },
  },

  mutations: {
    luRoles(state, payload) {
      state.luRoles = payload
    },
    selectedLuRole(state, payload) {
      state.selectedLuRole = JSON.parse(JSON.stringify(payload))
    },
    selectedLuRoleOriginal(state, payload) {
      state.selectedLuRoleOriginal = JSON.parse(JSON.stringify(payload))
    },
    saving(state, payload) {
      state.saving = payload
    },
    searchCount(state, payload) {
      state.searchCount = payload
    },
    increaseSearchCount(state, payload) {
      state.searchCount = state.searchCount + 1
    },
    decreaseSearchCount(state, payload) {
      state.searchCount = state.searchCount - 1
    },
  },

  actions: {
    setSelectedLuRole(context, payload) {
      context.commit('selectedLuRole', payload)
    },
    async loadLuRoles(context, payload) {
      const url = 'LuRole'
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('luRoles', res.data)
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    refreshSelectedLuRole(context, payload) {
      if (context.state.selectedLuRole && context.state.selectedLuRole.Id > 0) {
        context.dispatch('loadSelectedLuRole', context.state.selectedLuRole.Id)

      }
    },
    async loadSelectedLuRole(context, payload) {
      if (context.state.selectedLuRole && context.state.selectedLuRole.Id != payload) {
        context.commit('selectedLuRole', {})
      }
      const url = 'LuRole/' + payload
      context.commit('increaseSearchCount')
      try {
        let res = await Vue.prototype.$axios.get(url)
        context.commit('selectedLuRole', res.data)
        context.commit('selectedLuRoleOriginal', res.data)
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.commit('decreaseSearchCount')
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    clearLookupCacheAndReloadLuRoles(context, payload) {
      caches.delete('apilookup-cache').finally(() => {
          context.dispatch('loadLuRoles')
      }, )
    },
    async createLuRole(context, payload){
      const url = `LuRole`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios.post(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'role created',},{ root: true })
        //if you will always need to reload the list after an item is created
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuRoles')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async patchLuRole(context, payload){
      var item = context.state.selectedLuRole
      if (payload.Id && 
      payload.Id == context.state.selectedLuRoleOriginal.Id)  {
      item = payload
      }
      var patchPayload = {}
      if (!payload.patchDoc || !payload.Id) {
        let original = context.state.selectedLuRoleOriginal
        let current = item
        patchPayload.Id = item.Id
        var diff = jsonpatch.compare(
              original,
              current
            )
        patchPayload.patchDoc = diff

      } else {
        patchPayload = payload
      }
      if (patchPayload.patchDoc.length > 0) {
        const url = `LuRole/${ patchPayload.Id }`
        context.commit('saving', true)
        try {
          let res = await Vue.prototype.$axios
          .patch(url, patchPayload.patchDoc)
          context.dispatch('messages/toastMessage',
          {color: 'success', message: 'role updated',},{ root: true })
          //if you will always need to reload the list after an update
          //you can uncomment out the following dispatch line.
          //Alternatively you can reload whatever object you need
          //when the promise resolves in the component that called it.
          //context.dispatch('loadLuRoles')
          context.commit('saving', false)
          return res
        } catch (err) {
          console.error(err)
          context.commit('saving', false)
          context.dispatch('errors/handleError', err, { root: true })
          throw err
        }
      } else {
        return 'no changes'
      }
    },
    async updateLuRole(context, payload){
      const url = `LuRole/${ payload.Id }`
      context.commit('saving', true)
      try {
        let res = await Vue.prototype.$axios
          .put(url, payload)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'role updated',},{ root: true })
        //if you will always need to reload the list after an update
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuRoles')
        context.commit('saving', false)
        return res
      } catch (err) {
        console.error(err)
        context.commit('saving', false)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
    async saveLuRole(context, payload){
      if (payload.Id > 0) {
        return context.dispatch('updateLuRole', payload)
      } else {
        return context.dispatch('createLuRole', payload)
      }
    },
    async deleteLuRole(context, payload){
      const url = `LuRole/${ payload.Id }`
      try {
        let res = await Vue.prototype.$axios.delete(url)
        context.dispatch('messages/toastMessage',
        {color: 'success', message: 'role deleted',},{ root: true })
        //if you will always need to reload the list after a delete
        //you can uncomment out the following dispatch line.
        //Alternatively you can reload whatever object you need
        //when the promise resolves in the component that called it.
        //context.dispatch('loadLuRoles')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        throw err
      }
    },
  },
}

export default luRole