import Vue from 'vue'
import { debounce, range } from 'lodash'
import dayjs from 'dayjs'
//templateTODO: change this based on your default sort
const searchSortByDefault = 'ContactId'
const vwSummarySearch = {
  state: {
    searchTotal: 0,
    searchPage: 1,
    searchSortBy: [searchSortByDefault],
    searchDescending: [true],
    searchRowsPerPage: 25,
    searchText: '',
    lookupText: '',
    //templateTODO: uncomment as used in VwSummarySearchSearchParams
    searchStartDate: null,
    searchEndDate: null,
    // searchShowInactive: false,
    // searchPropertyIsActive: [],
    searchCountyId: [],
    searchCityId: [],
    searchApplicationPeriodId: [],
    searchStatusId: [],
    searchReasonId: [],
    searchStatusCreatedById: [],
  },

  getters: {
    searchParameters(state) {
      var sortDirection
      if (Array.isArray(state.searchDescending)) {
        sortDirection = state.searchDescending.map((x) => (x ? 'desc' : 'asc'))
      } else {
        sortDirection = state.searchDescending ? ['desc'] : ['asc']
      }
      return {
        pageNum: state.searchPage - 1,
        pageSize: state.searchRowsPerPage,
        sortProperty: state.searchSortBy,
        sortDirection,
        searchText: state.searchText,
        //templateTODO: uncomment as used in VwSummarySearchSearchParams
        startDate: state.searchStartDate,
        endDate: state.searchEndDate,
        // showInactive: state.searchShowInactive,
        //propertyIsActive: state.searchPropertyIsActive,
        countyId: state.searchCountyId,
        cityId: state.searchCityId,
        applicationPeriodId: state.searchApplicationPeriodId,
        statusId: state.searchStatusId,
        reasonId: state.searchReasonId,
        statusCreatedById: state.searchStatusCreatedById,
      }
    },
  },

  mutations: {
    searchTotal(state, payload) {
      state.searchTotal = payload
    },
    searchPage(state, payload) {
      state.searchPage = payload
    },
    searchSortBy(state, payload) {
      state.searchSortBy = payload
    },
    searchDescending(state, payload) {
      state.searchDescending = payload
    },
    searchRowsPerPage(state, payload) {
      state.searchRowsPerPage = payload
    },
    searchText(state, payload) {
      state.searchText = payload
    },
    lookupText(state, payload) {
      state.lookupText = payload
    },
    //TemplateTODO: uncomment as needed
    searchStartDate(state, payload) {
      state.searchStartDate = payload
    },
    searchEndDate(state, payload) {
      state.searchEndDate = payload
    },
    // searchShowInactive(state, payload) {
    //   state.searchShowInactive = payload
    // },
    // searchPropertyIsActive(state, payload) {
    //   state.searchPropertyIsActive = payload
    // },
    searchCountyId(state, payload) {
      state.searchCountyId = payload
    },
    searchCityId(state, payload) {
      state.searchCityId = payload
    },
    searchApplicationPeriodId(state, payload) {
      state.searchApplicationPeriodId = payload
    },
    searchStatusId(state, payload) {
      state.searchStatusId = payload
    },
    searchReasonId(state, payload) {
      state.searchReasonId = payload
    },
    searchStatusCreatedById(state, payload) {
      state.searchStatusCreatedById = payload
    },
  },

  actions: {
    lookupBounce: _.debounce((context) => {
      context.dispatch('lookupVwSummarySearches')
    }, 300),
    searchBounce: _.debounce((context) => {
      context.dispatch('searchVwSummarySearches')
    }, 750),
    resetSearchParams(context, payload) {
      context.commit('searchText', '')
      context.commit('searchStartDate', null)
      context.commit('searchEndDate', null)
      context.commit('searchCountyId', [])
      context.commit('searchCityId', [])
      context.commit('searchApplicationPeriodId', [])
      context.commit('searchStatusId', [])
      context.commit('searchReasonId', [])
      context.commit('searchStatusCreatedById', [])
    },
    setSearchPage(context, payload) {
      if (payload != context.state.searchPage) {
        context.commit('searchPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchSortBy(context, payload) {
      if (
        JSON.stringify(payload) != JSON.stringify(context.state.searchSortBy)
      ) {
        context.commit('searchSortBy', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchDescending(context, payload) {
      if (
        JSON.stringify(payload) !=
        JSON.stringify(context.state.searchDescending)
      ) {
        context.commit('searchDescending', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchRowsPerPage(context, payload) {
      if (payload != context.state.searchRowsPerPage) {
        context.commit('searchRowsPerPage', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchText(context, payload) {
      if (payload != context.state.searchText) {
        context.commit('searchText', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setLookupText(context, payload) {
      if (payload != context.state.lookupText && payload != null) {
        context.commit('lookupText', payload)
        context.dispatch('lookupBounce', context)
      }
    },
    //TemplateTODO: uncomment as needed
    setSearchStartDate(context, payload) {
      if (payload != context.state.searchStartDate) {
        context.commit('searchStartDate', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchEndDate(context, payload) {
      if (payload != context.state.searchEndDate) {
        context.commit('searchEndDate', payload)
        context.dispatch('searchBounce', context)
      }
    },
    //setSearchShowInactive(context, payload) {
    //  if (payload != context.state.searchShowInactive) {
    //    context.commit('searchShowInactive', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    //setSearchPropertyIsActive(context, payload) {
    //  if (payload != context.state.searchPropertyIsActive) {
    //    context.commit('searchPropertyIsActive', payload)
    //    context.dispatch('searchBounce', context)
    //  }
    //},
    setSearchCountyId(context, payload) {
      if (payload != context.state.searchCountyId) {
        context.commit('searchCountyId', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchCityId(context, payload) {
      if (payload != context.state.searchCityId) {
        context.commit('searchCityId', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchApplicationPeriodId(context, payload) {
      if (payload != context.state.searchApplicationPeriodId) {
        context.commit('searchApplicationPeriodId', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchStatusId(context, payload) {
      if (payload != context.state.searchStatusId) {
        context.commit('searchStatusId', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchReasonId(context, payload) {
      if (payload != context.state.searchReasonId) {
        context.commit('searchReasonId', payload)
        context.dispatch('searchBounce', context)
      }
    },
    setSearchStatusCreatedById(context, payload) {
      if (payload != context.state.searchStatusCreatedById) {
        context.commit('searchStatusCreatedById', payload)
        context.dispatch('searchBounce', context)
      }
    },
    async searchVwSummarySearches(context, payload) {
      if (!context.state.searchSortBy) {
        context.commit('searchSortBy', [searchSortByDefault])
        context.commit('searchDescending', [true])
      }
      if (!Array.isArray(context.state.searchSortBy)) {
        if (context.state.searchSortBy) {
          context.commit('searchSortBy', [context.state.searchSortBy])
          context.commit('searchDescending', [context.state.searchDescending])
        } else {
          context.commit('searchSortBy', [searchSortByDefault])
          context.commit('searchDescending', [true])
        }
      }
      if (!context.state.searchText) {
        context.commit('searchText', '')
      }
      context.commit('increaseSearchCount')
      var params = context.getters.searchParameters
      var paramString = JSON.stringify(params)
      const url = `VwSummarySearch/Search`
      try {
        let res = await Vue.prototype.$axios.post(url, params)
        if (JSON.stringify(context.getters.searchParameters) == paramString) {
          context.commit('vwSummarySearches', res.data.Entries)
          context.commit('searchRowsPerPage', res.data.Page.Size)
          context.commit('searchPage', res.data.Page.Number + 1)
          context.commit('searchTotal', res.data.Page.TotalElements)
        }
        context.commit('decreaseSearchCount')
        return res
      } catch (err) {
        console.error(err)
        context.dispatch('errors/handleError', err, { root: true })
        context.commit('decreaseSearchCount')
        throw err
      }
    },
    async lookupVwSummarySearches(context, payload) {
      if (context.state.lookupText) {
        context.commit('increaseSearchCount')
        const url = `VwSummarySearch/Lookup?searchText=${context.state.lookupText}`
        try {
          let res = await Vue.prototype.$axios.get(url)
          context.commit('vwSummarySearches', res.data)
          context.commit('decreaseSearchCount')
          return res
        } catch (err) {
          console.error(err)
          context.dispatch('errors/handleError', err, { root: true })
          context.commit('decreaseSearchCount')
          throw err
        }
      }
    },
  },
}

export default vwSummarySearch
